import { Entity, EntityMeta } from '@backstage/catalog-model';
import { generateAjvJsonSchemaValidator } from '../utils';
import schema from '../schema/Blueprint.v1alpha1.schema.json';

declare type TemplateDependency = {
  urnTemplate: string;
  dependsFrom: Array<string>;
};

declare interface BlueprintEntity extends Entity {
  apiVersion: 'agilelab.it/v1alpha1';
  kind: 'Blueprint';
  metadata: EntityMeta;
  spec: {
    lifecycle?: string;
    owner?: string;
    domain?: string;
    mainTemplateId: string;
    templates: {
      id: string;
      dependencies?: string[];
    }[];
  };
}

export type { BlueprintEntity, TemplateDependency };

export const blueprintEntityV1alpha1Validator =
  generateAjvJsonSchemaValidator(schema);
