import {
  createApiFactory,
  createPlugin,
  discoveryApiRef,
  fetchApiRef,
} from '@backstage/core-plugin-api';
import {
  CustomPresentationRetriever,
  CustomPresentationRetrieverApiRef,
} from './apis';

export const platformPlugin = createPlugin({
  id: 'platform',
  apis: [
    createApiFactory({
      api: CustomPresentationRetrieverApiRef,
      deps: {
        discoveryApi: discoveryApiRef,
        fetchApi: fetchApiRef,
      },
      factory: ({ discoveryApi, fetchApi }) =>
        new CustomPresentationRetriever({ discoveryApi, fetchApi }),
    }),
  ],
});
