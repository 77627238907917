import React from 'react';
import { Editor, EditorProps, Monaco as MonacoLib } from '@monaco-editor/react';
import type monaco from 'monaco-editor';

export type WbEditorProps = EditorProps;
export type MonacoEditor = monaco.editor.IStandaloneCodeEditor;
export type Monaco = MonacoLib;
export type IOverlayWidget = monaco.editor.IOverlayWidget;
/**
 * Web Editor Component of witboost.
 *
 * @param {Object} props - The properties for the WbEditor component, same as Monaco Editor.
 * @param {string} props.value - The initial value of the editor.
 * @param {string} props.theme - The theme of the editor.
 * @param {string} props.language - The language mode of the editor. Defaults to "yaml".
 */
export const WbEditor = (props: WbEditorProps): JSX.Element => {
  const { value, theme, language, onMount: customOnMount, ...wbProps } = props;

  return (
    <Editor
      value={value}
      theme={theme ?? 'default'}
      language={language ?? 'yaml'}
      onMount={(editorInstance, monaco) => {
        if (editorInstance) {
          editorInstance.updateOptions({ scrollBeyondLastLine: false });
          if (customOnMount) customOnMount(editorInstance, monaco);
        }
      }}
      options={{
        wordWrap: 'on',
        readOnly: false,
        lineNumbers: 'on',
        minimap: {
          enabled: true,
        },
        ...wbProps.options,
      }}
      {...wbProps}
    />
  );
};
