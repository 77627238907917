import { Box, Divider, useTheme } from '@material-ui/core';
import React from 'react';

interface Props {
  orientation?: 'vertical' | 'horizontal';
  height?: string;
}

export const WbDivider: React.FC<Props> = ({
  orientation = 'vertical',
  height,
}) => {
  const theme = useTheme();
  return orientation === 'vertical' ? (
    <Box
      style={{
        background: theme.palette.grey[300],
        width: '1px',
        height: height ?? '24px',
      }}
    />
  ) : (
    <Divider />
  );
};
