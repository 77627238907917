import { alertApiRef, useApi } from '@backstage/core-plugin-api';
import {
  Badge,
  Box,
  capitalize,
  Link,
  makeStyles,
  Slide,
  SlideProps,
} from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import Snackbar from '@material-ui/core/Snackbar';
import CloseIcon from '@material-ui/icons/Close';
import { Alert } from '@material-ui/lab';
import AlertTitle from '@material-ui/lab/AlertTitle';
import React, { useEffect, useState } from 'react';

import { customAlertApiRef } from '../../apis';
import { CustomAlertMessage } from '../../apis/AlertApi/CustomAlertApi';
import { CustomAlertDetails } from './CustomAlertDetails';
import {
  CustomError,
  CoordinatorError,
  CustomErrorName,
} from '@agilelab/plugin-wb-platform-common';

/**
 * Displays alerts from {@link @backstage/core-plugin-api#AlertApi}
 * with respect to the original backstage AlertApi,
 * and custom alerts from CustomAlertApi.
 *
 * @public
 * @remarks
 *
 * Shown as SnackBar at the top of the page
 */
function SlideTransition(props: SlideProps) {
  return <Slide {...props} direction="down" />;
}

const useStyles = makeStyles(theme => ({
  snackbar: {
    minWidth: '20%',
    maxWidth: '100%',
    zIndex: 1300,
  },
  alert: {
    width: '100%',
  },
  title: {
    overflowWrap: 'anywhere',
    display: 'flex',
    gap: '8px',
    alignItems: 'start',
    flexDirection: 'column',
  },
  alertContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  detailsLink: {
    marginTop: theme.spacing(1),
    color: theme.palette.error.main,
    textDecoration: 'underline',
    fontWeight: 500,
  },
  subMessage: {
    whiteSpace: 'pre-line',
  },
}));

function handleMultipleAlerts(alert: JSX.Element, length: number): JSX.Element {
  return length > 1 ? (
    <Badge
      badgeContent={`${length - 1} more`}
      color="primary"
      style={{ width: '100%' }}
    >
      {alert}
    </Badge>
  ) : (
    alert
  );
}

export function CustomAlertDisplay(_props: {}) {
  const [messages, setMessages] = useState<Array<CustomAlertMessage>>([]);
  const [open, setOpen] = useState(true);
  const [detailsOpen, setDetailsOpen] = useState(false);
  const alertApi = useApi(alertApiRef);
  const customAlertApi = useApi(customAlertApiRef);
  const classes = useStyles();

  useEffect(() => {
    const subscription = alertApi
      .alert$()
      .subscribe(message => setMessages(msgs => [message, ...msgs]));

    return () => {
      subscription.unsubscribe();
    };
  }, [alertApi]);

  useEffect(() => {
    const subscription = customAlertApi
      .alert$()
      .subscribe(message => setMessages(msgs => [message, ...msgs]));

    return () => {
      subscription.unsubscribe();
    };
  }, [customAlertApi]);

  if (messages.length === 0) {
    return null;
  }

  if (!open && messages.length === 0) {
    return null;
  }

  const [firstMessage] = messages;
  const severity = firstMessage.severity || 'success';
  const error = firstMessage.error;

  const customError: CustomError | undefined =
    error?.name === CustomErrorName.CustomError ||
    error?.name === CustomErrorName.GitRepositoryError
      ? (error as CustomError)
      : undefined;

  const coordinatorError: CoordinatorError | undefined =
    error?.name === CustomErrorName.CoordinatorError
      ? (error as CoordinatorError)
      : undefined;

  const errorMessage: string | undefined =
    customError?.message || coordinatorError?.message || error?.message;

  const submessage: string =
    customError?.errorMessage ||
    coordinatorError?.moreInfo?.solutions[0] ||
    coordinatorError?.moreInfo?.problems[0] ||
    '';

  const handleClose = () => {
    setDetailsOpen(false);
    setMessages(msgs => msgs.filter(msg => msg !== firstMessage));
    setOpen(false);
  };

  if (firstMessage.timeoutMillis || severity === 'success') {
    setTimeout(() => {
      handleClose();
    }, firstMessage.timeoutMillis ?? 20000);
  }

  return (
    <Snackbar
      open
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      TransitionComponent={SlideTransition}
      className={classes.snackbar}
    >
      {handleMultipleAlerts(
        <Alert
          className={classes.alert}
          action={
            <IconButton
              style={{ alignSelf: 'start' }}
              color="inherit"
              size="small"
              onClick={handleClose}
              data-testid="error-button-close"
            >
              <CloseIcon />
            </IconButton>
          }
          severity={severity}
        >
          <AlertTitle className={classes.title}>
            {capitalize(
              errorMessage || firstMessage.message?.toString() || severity,
            )}
            {firstMessage.actions && (
              <Box onClick={handleClose}>{firstMessage.actions}</Box>
            )}
          </AlertTitle>
          {!!(customError || coordinatorError) && (
            <div className={classes.alertContent}>
              <span className={classes.subMessage}>
                {capitalize(submessage)}
              </span>

              {!!coordinatorError && (
                <>
                  <Link
                    component="button"
                    onClick={() => setDetailsOpen(!detailsOpen)}
                    className={classes.detailsLink}
                  >
                    Show more
                  </Link>
                  <CustomAlertDetails
                    open={detailsOpen}
                    errorInfo={coordinatorError}
                    onClose={() => setDetailsOpen(false)}
                  />
                </>
              )}
            </div>
          )}
        </Alert>,
        messages.length,
      )}
    </Snackbar>
  );
}
