/*
 * This component is copied from Backstage 1.8.2.
 * CHANGES:
 * - Added custom UI
 */

import {
  useEntityList,
  useStarredEntities,
  useEntityOwnership,
  UserListFilter,
  UserListFilterKind,
} from '@backstage/plugin-catalog-react';
import React, { useEffect } from 'react';
import { BooleanFilter } from '../WbTableFilters';

export const UserListPicker: React.FC = () => {
  const { updateFilters, filters } = useEntityList();
  const { isStarredEntity, starredEntities } = useStarredEntities();
  const { isOwnedEntity } = useEntityOwnership();

  useEffect(() => {
    if (!starredEntities.size)
      updateFilters({
        user: new UserListFilter(
          'all' as UserListFilterKind,
          isOwnedEntity,
          isStarredEntity,
        ),
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [starredEntities]);

  return (
    <BooleanFilter
      value={filters.user?.value === 'starred' ? true : false}
      field="Favourites"
      onChange={checked => {
        updateFilters({
          user: new UserListFilter(
            (checked ? 'starred' : 'all') as UserListFilterKind,
            isOwnedEntity,
            isStarredEntity,
          ),
        });
      }}
    />
  );
};
