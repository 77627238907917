import { Box, makeStyles, Tooltip, Typography } from '@material-ui/core';
import React from 'react';
import { Error } from './Error';
import { Handle, NodeProps, Position } from 'reactflow';
import { usePracticeShaperGraph } from '../../../context/PracticeShaperGraphContext';
import { ConsumableIcon, ShoppableIcon } from '../icons/ShoppableIcon';
import { EntityNodeData } from '../PracticeShaperGraph';

type DefaultNodeProps = NodeProps<EntityNodeData>;

export const ENTITY_NODE_WIDTH = 220;

export const ENTITY_NODE_HEIGHT = 40;

export const SELF_CONNECTING_ENTITY_NODE_WIDTH = 520;

const useStyles = makeStyles(
  theme => ({
    entityNode: {
      color: theme.palette.white,
      width: ENTITY_NODE_WIDTH,
      height: ENTITY_NODE_HEIGHT,
      padding: `0 ${theme.spacing(1.5)}px`,
      borderRadius: '10px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      cursor: 'pointer',
      '& p': {
        fontWeight: 'bold',
      },
      '&.SystemType': {
        backgroundColor: theme.palette.relationsGraph.dataProduct,
      },
      '&.ComponentType': {
        backgroundColor: theme.palette.relationsGraph.component,
      },
      '&.Taxonomy': {
        backgroundColor: theme.palette.relationsGraph.taxonomy,
      },
      '&.DomainType': {
        backgroundColor: theme.palette.relationsGraph.domain,
      },
      '&.Disabled': {
        filter: 'opacity(50%)',
      },
    },
    label: {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    content: {
      display: 'flex',
      alignItems: 'center',
      width: '100%',
      justifyContent: 'center',
      gap: theme.spacing(1),
    },
    iconsContainer: {
      marginLeft: 'auto',
      display: 'flex',
      alignItems: 'center',
      gap: theme.spacing(1),
    },
  }),
  { name: 'EntiyNode' },
);

export const EntityNode = (props: DefaultNodeProps) => {
  const { data } = props;
  const classes = useStyles();

  const { setSelectedEntity } = usePracticeShaperGraph();

  const shoppable =
    (data.kind === 'ComponentType' || data.kind === 'SystemType') &&
    data.spec.shoppable;

  const consumable =
    (data.kind === 'ComponentType' || data.kind === 'SystemType') &&
    data.spec.consumable;

  const hasIcons = shoppable || consumable;

  const disabled = props.data.kind === 'Taxonomy' && !props.data.spec.enabled;

  return (
    <Box
      className={`${classes.entityNode} ${props.data.kind} ${
        disabled ? 'Disabled' : ''
      }`}
      onClick={() => setSelectedEntity(props.data)}
    >
      {props.data.error && <Error label="Processing error" />}
      <Box className={classes.content}>
        <Tooltip title={props.data.label}>
          <Typography className={classes.label} variant="body2">
            {props.data.label}
          </Typography>
        </Tooltip>
        {hasIcons && (
          <Box className={classes.iconsContainer}>
            {shoppable && (
              <Tooltip title="Shoppable">
                <ShoppableIcon fontSize="small" />
              </Tooltip>
            )}
            {consumable && (
              <Tooltip title="Consumable">
                <ConsumableIcon fontSize="small" />
              </Tooltip>
            )}
          </Box>
        )}
      </Box>

      <Handle
        type="source"
        id="self-source"
        style={{ visibility: 'hidden' }}
        position={Position.Right}
      />
      <Handle
        type="target"
        id="self-target"
        style={{ visibility: 'hidden' }}
        position={Position.Right}
      />
      <Handle
        type="target"
        id="bottom"
        style={{ visibility: 'hidden', bottom: -1 }}
        position={Position.Bottom}
      />
      <Handle
        type="source"
        id="top"
        style={{ visibility: 'hidden', top: 0 }}
        position={Position.Top}
      />
    </Box>
  );
};
