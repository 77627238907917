import React, { useState } from 'react';
import { CustomViewRegister } from '../CustomView';
import {
  ActionsContextProvider,
  DataRootPathContextProvider,
  useAction,
  useDataPath,
} from '../../context';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import {
  Box,
  Checkbox,
  makeStyles,
  Radio,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
  useTheme,
  Button,
  IconButton,
} from '@material-ui/core';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';

import clsx from 'clsx';
import { parseNunjucks } from '@agilelab/plugin-wb-platform-common';
import { getChildren } from '../CustomView';
import _ from 'lodash';
import { LinkIcon } from '../Icons';

// the offset every nested row has compared to its parent in px
const TREE_LEVEL_PADDING = 25;

const useStyles = makeStyles(theme => ({
  tableRow: {
    position: 'relative',
    borderBottom: '1px solid white',
    backgroundColor: theme.palette.background.default,
    '&:hover': {
      backgroundColor: theme.palette.bkg.primary,
    },
  },
  treeCellContainer: {
    display: 'flex',
    alignItems: 'center',
  },
}));

const processShowWhenRow = (
  showRowWhen: { value?: string; equals?: string; notEquals?: string },
  item: Record<string, any>,
): Record<string, any> | null => {
  if (showRowWhen?.value) {
    const parsedValue =
      parseNunjucks(showRowWhen.value, item, true) ?? showRowWhen.value;
    const parsedEqual = showRowWhen.equals;
    const parsedNotEqual = showRowWhen.notEquals;

    if (parsedEqual !== undefined && parsedValue !== parsedEqual?.toString())
      return null;
    if (
      parsedNotEqual !== undefined &&
      parsedValue === parsedNotEqual?.toString()
    )
      return null;
  }
  return item;
};
const explodeTreeView = (
  list: Record<string, any>[],
  key: string,
  level: number = 0,
  path: number[] = [],
) =>
  list.flatMap((elem: any, i: number) => {
    let result = [
      Object.assign(
        {
          _treeview: {
            level,
            hasChildren: key in elem,
            id: [path.length, i].join('/'),
            path: path.concat([i]).map((p, k) => [k, p].join('/')),
          },
        },
        elem,
      ),
    ];
    if (key in elem)
      result = result.concat(
        explodeTreeView(elem[key], key, level + 1, path.concat([i])),
      );
    return result;
  });

CustomViewRegister.register({
  id: 'table',
  function: function TableView(props: Record<string, any>) {
    const treeview: string = props.treeview || '';
    const hideEmptyColumns = props.hideEmptyColumns || false;
    const clickFn = useAction(props.click);
    const theme = useTheme();
    const [closedNodes, setClosedNodes] = useState<string[]>([]);
    const classes = useStyles();
    const parent = useDataPath('');
    let list: any[] = useDataPath(props.path) ?? [];
    const rowsNumber = list.length;
    let isAnyRowNested = false;
    if (!Array.isArray(list)) return <></>;
    if (treeview !== '') {
      list = explodeTreeView(list, treeview);
      // if there are extra rows, some row was nested
      isAnyRowNested = list.length !== rowsNumber;
    }
    list = list.map((elem: any) =>
      Object.assign({}, elem, { _parent: parent }),
    );
    const showRowWhen = props.showRowWhen;

    const filteredList = list
      .map(row => processShowWhenRow(showRowWhen, row))
      .filter(
        (row: any) =>
          !closedNodes.some((closedNode: string) => {
            return row._treeview.path.slice(0, -1).indexOf(closedNode) >= 0;
          }),
      );
    const onClick = (ev: any, rowData: any, idx: number) => {
      const tag = (ev.target as HTMLElement).tagName ?? '';
      const tagParent = (ev.target as HTMLElement).parentElement?.tagName ?? '';
      // bypass if user clicks on a link
      if (tag !== 'A' && tagParent !== 'A') clickFn(rowData, idx);
    };

    const style = props.style || {};
    const columns = getChildren(props)
      .map(elem => ({
        elem,
        title: elem.props.label || elem.props.title,
        width: elem.props.width || '',
        align: elem.props.align || '',
        valueRef: !!elem.props.value
          ? elem.props.value
          : ['{{', elem.props.path, '}}'].join(' '),
        showWhenExistsPath: elem.props.showWhenExistsPath || '',
      }))
      .filter(elem => {
        if (hideEmptyColumns) {
          if (!filteredList.some(row => !!parseNunjucks(elem.valueRef, row)))
            return false;
        }
        if ((elem.showWhenExistsPath || '') === '') return true;
        return !!_.get(
          (list as Record<string, any>)._parent,
          elem.showWhenExistsPath,
        );
      });
    const rowClassName = props.rowClass || '';
    const rowStyle: React.CSSProperties = {};
    if (props.click) rowStyle.cursor = 'pointer';
    const closeTreeViewNode = (rowData: any) => {
      setClosedNodes(closedNodes.concat([rowData._treeview.id]));
    };
    const openTreeViewNode = (rowData: any) => {
      setClosedNodes(closedNodes.filter(node => node !== rowData._treeview.id));
    };
    const isOpen = (treeViewItem: any) => {
      return closedNodes.indexOf(treeViewItem.id) < 0;
    };
    const getTreeViewIndicator = (rowData: any) =>
      isOpen(rowData._treeview) ? (
        <IconButton size="small" onClick={() => closeTreeViewNode(rowData)}>
          <KeyboardArrowDownIcon />
        </IconButton>
      ) : (
        <IconButton size="small" onClick={() => openTreeViewNode(rowData)}>
          <KeyboardArrowRightIcon />
        </IconButton>
      );

    return (
      <ActionsContextProvider actions={{ getMode: () => 'table' }}>
        <TableContainer style={props.styles?.container}>
          <Table stickyHeader={props.stickyHeader || false} size="small">
            <TableHead
              style={{ background: theme.palette.background.default, ...style }}
            >
              <TableRow>
                {columns.map((column, cellIndex) => {
                  return (
                    <TableCell
                      key={`cell-index-${cellIndex}`}
                      style={{ width: column.width }}
                      align={column.align}
                    >
                      <Typography
                        color="primary"
                        component="div"
                        variant="button"
                      >
                        <Box>{column.title}</Box>
                      </Typography>
                    </TableCell>
                  );
                })}
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredList.map((rowData: any, idx: number) => {
                if (!rowData) return null;
                const index = `${props.path}[${idx}]`;
                const cellProps = {};
                return (
                  <TableRow
                    key={index}
                    style={rowStyle}
                    className={clsx(classes.tableRow, rowClassName)}
                    onClick={ev => onClick(ev, rowData, idx)}
                  >
                    <DataRootPathContextProvider
                      key={String(idx)}
                      data={rowData}
                    >
                      {columns
                        .map(c => c.elem)
                        .map((elem, cellIndex) => {
                          const isTreeCell =
                            cellIndex === 0 &&
                            treeview !== '' &&
                            isAnyRowNested;
                          return (
                            <TableCell
                              key={`cell-index-${cellIndex}`}
                              style={isTreeCell ? { paddingLeft: 0 } : {}}
                              {...cellProps}
                            >
                              {isTreeCell ? (
                                <Box
                                  className={classes.treeCellContainer}
                                  style={{
                                    paddingLeft: [
                                      rowData._treeview.level *
                                        TREE_LEVEL_PADDING,
                                      'px',
                                    ].join(''),
                                  }}
                                >
                                  <Box
                                    visibility={
                                      rowData._treeview.hasChildren
                                        ? 'visible'
                                        : 'hidden'
                                    }
                                  >
                                    {getTreeViewIndicator(rowData)}
                                  </Box>
                                  {elem}
                                </Box>
                              ) : (
                                elem
                              )}
                            </TableCell>
                          );
                        })}
                    </DataRootPathContextProvider>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </ActionsContextProvider>
    );
  },
});

CustomViewRegister.register({
  id: 'radio',
  function: function RadioButton(props: Record<string, any>) {
    const action = useAction(props.click);
    const data = useDataPath('');
    const nunjucksDisabled = parseNunjucks(props.disabled, data, true);
    const disabled =
      nunjucksDisabled !== undefined ? !!JSON.parse(nunjucksDisabled) : false;

    return (
      <Tooltip
        title={disabled ? props.disabledTooltip ?? '' : props.tooltip ?? ''}
      >
        <span>
          <Radio
            onClick={e => {
              e.stopPropagation();
              action(data);
            }}
            checked={Boolean(props.value)}
            color="primary"
            disabled={disabled}
          />
        </span>
      </Tooltip>
    );
  },
});

CustomViewRegister.register({
  id: 'checkbox',
  function: function RadioButton(props: Record<string, any>) {
    const action = useAction(props.click);
    const data = useDataPath('');
    const nunjucksDisabled = parseNunjucks(props.disabled, data, true);
    const disabled =
      nunjucksDisabled !== undefined ? !!JSON.parse(nunjucksDisabled) : false;

    return (
      <Tooltip
        title={disabled ? props.disabledTooltip ?? '' : props.tooltip ?? ''}
      >
        <span>
          <Checkbox
            onClick={e => {
              e.stopPropagation();
              action(data);
            }}
            checked={Boolean(props.value)}
            color="primary"
            disabled={disabled}
          />
        </span>
      </Tooltip>
    );
  },
});

CustomViewRegister.register({
  id: 'enabled',
  function: function RadioButton(props: Record<string, any>) {
    const theme = useTheme();
    const color = props.value
      ? theme.palette.success.main
      : theme.palette.grey[300];
    return <CheckCircleIcon htmlColor={color} />;
  },
});

CustomViewRegister.register({
  id: 'row_link',
  function: function RowLink(props: Record<string, any>) {
    const click = useAction(props.click);
    const rowData = useDataPath('');
    if (!props.value) return <></>;
    return (
      <Button
        onClick={() => click(rowData)}
        aria-label={props.label}
        title={props.label}
        size="small"
      >
        <LinkIcon fontSize="small" />
      </Button>
    );
  },
});
