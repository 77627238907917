import {
  customAlertApiRef,
  WbCardActionButton,
  WbLink,
} from '@agilelab/plugin-wb-platform';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import React, { useMemo } from 'react';
import { useReleasePageContext } from './context/useReleasePageContext';
import { useApi, useRouteRef } from '@backstage/core-plugin-api';
import parseGitUrl from 'git-url-parse';
import { panelCatalogApiRef } from '../../api';
import { stringifyEntityRef } from '@backstage/catalog-model';
import { builderDpSnapshotCreatePermission } from '@agilelab/plugin-wb-rbac-common';
import { usePermission } from '@backstage/plugin-permission-react';
import { ButtonProps, Tooltip } from '@material-ui/core';
import { useNavigate } from 'react-router';
import { releaseDetailRouteRef } from '../../routes';
import { generateSnapshotButtonTooltip, getReleaseInfo } from '../../utils';
import { getEntitySourceLocation } from '@backstage/plugin-catalog-react';
import { EntityAndBranch } from '@agilelab/plugin-wb-builder-common';
import { scmIntegrationsApiRef } from '@backstage/integration-react';

type Props = ButtonProps;

export const NewDraftReleaseButton: React.FC<Props> = props => {
  const {
    isLoading,
    isReleasing,
    setIsReleasing,
    addRelease,
    entity,
    releases,
    relatedEntities,
    processorErrors,
  } = useReleasePageContext();
  const panelCatalog = useApi(panelCatalogApiRef);
  const alertApi = useApi(customAlertApiRef);
  const scmIntegrationsApi = useApi(scmIntegrationsApiRef);

  const navigate = useNavigate();
  const releaseDetailRoute = useRouteRef(releaseDetailRouteRef);

  const { allowed: canCreateSnapshot } = usePermission({
    permission: builderDpSnapshotCreatePermission,
    resourceRef: stringifyEntityRef({
      kind: entity.kind ?? 'system',
      namespace: 'default',
      name: entity.metadata.name,
    }),
  });

  const createSnapshot = async () => {
    try {
      const entities: EntityAndBranch[] = [entity, ...relatedEntities!].map(
        en => {
          const entitySourceLocation = getEntitySourceLocation(
            en,
            scmIntegrationsApi,
          );
          const branchFromLocationUrl = entitySourceLocation?.locationTargetUrl
            ? parseGitUrl(entitySourceLocation.locationTargetUrl).ref
            : 'master';

          return {
            branch: branchFromLocationUrl,
            entityRef: stringifyEntityRef(en),
          };
        },
      );

      setIsReleasing(true);
      const newRelease = await panelCatalog.createSnapshot(
        entity.metadata.name,
        entity.kind,
        entities,
      );

      const { kind, namespace, name } = getReleaseInfo(newRelease);

      addRelease({ ...newRelease, deployments: [] });
      alertApi.post({
        actions: (
          <WbLink
            route={releaseDetailRoute({ kind, namespace, name })}
            text={`Open V${newRelease?.metadata?.version}`}
            navigate={navigate}
          />
        ),
        message: 'New Draft-Release created successfully.',
        severity: 'success',
      });
    } catch (error) {
      alertApi.post({
        error,
        severity: 'error',
      });
    } finally {
      setIsReleasing(false);
    }
  };

  const hasAlreadySnapshot = useMemo(
    () => Boolean(releases.find(release => release.metadata.isSnapshot)),
    [releases],
  );

  return (
    <Tooltip
      title={generateSnapshotButtonTooltip(
        canCreateSnapshot,
        hasAlreadySnapshot,
        processorErrors,
      )}
    >
      <span>
        <WbCardActionButton
          icon={<AddCircleIcon />}
          label="New Draft Release"
          loading={isReleasing}
          disabled={
            isLoading ||
            hasAlreadySnapshot ||
            !canCreateSnapshot ||
            !!processorErrors?.length
          }
          onClick={createSnapshot}
          {...props}
        />
      </span>
    </Tooltip>
  );
};
