import { Environment } from '../types';

export function compareEnvironments(a: Environment, b: Environment) {
  function hasPriority(env: Environment) {
    return typeof env.priority === 'number';
  }

  if (hasPriority(a) && hasPriority(b)) {
    return a.priority! - b.priority!; // Smaller is first
  }
  if (hasPriority(a)) {
    return -1;
  }
  if (hasPriority(b)) {
    return 1;
  }
  return a.name.localeCompare(b.name);
}
