import { AuditEntry } from '@agilelab/plugin-wb-audit-common';
import { Operation } from '@apollo/client';
import { parseEntityRef } from '@backstage/catalog-model';
import { BackstageUserIdentity } from '@backstage/core-plugin-api';
import { DateTime } from 'luxon';

export function mapToAuditEntry(
  operation: Operation,
  backstageId: BackstageUserIdentity,
  hasuraAddress?: string,
  hasuraEndpoint?: string,
): AuditEntry | undefined {
  if (
    operation.query.definitions.findIndex(
      (definition: any) => definition.operation === 'mutation',
    ) === -1
  ) {
    return undefined;
  }

  return {
    user: parseEntityRef(backstageId.userEntityRef).name,
    userAgent: 'hasura',
    date: DateTime.now().toUTC().toISO(),
    remoteAddress: hasuraAddress,
    url: hasuraEndpoint,
    http: 'GraphQL',
    variables: operation.variables,
    query: operation.query,
    operationName: operation.operationName,
    extensions: operation.extensions,
  };
}
