import { gql } from '@apollo/client/core';
import {
  ExternalOutputPort,
  OutputPortWithWarnings,
} from '../components/types';

export const GET_OUTPUT_PORT_BY_ID = gql`
  query getOutputPortById($id: bigint) {
    instances: marketplace_instances(where: { id: { _eq: $id } }) {
      id
      description
      name
      display_name
      version
      descriptor
      external_id
      shoppable
      consumable
      kind
      system: relations(where: { name: { _eq: "partOfSystem" } }) {
        data: instanceByTargetInstanceId {
          id
          name
          display_name
          descriptor
          version
          domain: relations(where: { name: { _eq: "partOfDomain" } }) {
            data: instanceByTargetInstanceId {
              name
              external_id
            }
          }
          environment {
            id
            name
            priority
          }
        }
      }
    }
  }
`;

export const GET_OUTPUT_PORT_AND_WARNINGS_BY_ID = gql`
  query getOutputPortAndWarningsById(
    $id: bigint
    $consumableInterfaceTypeField: String!
  ) {
    marketplace_instances(where: { id: { _eq: $id } }) {
      system: relations(where: { name: { _eq: "partOfSystem" } }) {
        data: instanceByTargetInstanceId {
          id
          name
          display_name
          domain: relations(where: { name: { _eq: "partOfDomain" } }) {
            data: instanceByTargetInstanceId {
              name
            }
          }
          owner_display_name
          consumable
          shoppable
          environment {
            name
          }
        }
      }
      id
      descriptor
      display_name
      name
      mode: additional_attributes(path: "$.mode")
      outputporttype: descriptor(path: $consumableInterfaceTypeField)
      port_warnings {
        id
        message
        content
        kind
      }
    }
  }
`;

export const GET_OUTPUT_PORT_BY_EXTERNAL_ID = gql`
  query getOutputPortByExternalId($externalIds: [String]) {
    marketplace_instances(where: { external_id: { _in: $externalIds } }) {
      id
      external_id
      display_name
      system: relations(where: { name: { _eq: "partOfSystem" } }) {
        data: instanceByTargetInstanceId {
          id
          display_name
          name
          domain: relations(where: { name: { _eq: "partOfDomain" } }) {
            data: instanceByTargetInstanceId {
              name
            }
          }
        }
      }
    }
  }
`;

export interface IGetOutputPortAndWarningsByIdData {
  marketplace_instances: OutputPortWithWarnings[];
}

export interface IGetOutputPortByIdVars {
  id: number;
  consumableInterfaceTypeField: string;
}

export interface IGetOutputPortByExternalIdData {
  marketplace_instances: ExternalOutputPort[];
}

export interface IGetOutputPortByExternalIdVars {
  externalIds: string[];
}
