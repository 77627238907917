import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  IconButton,
  MenuItem,
  Select,
  Typography,
  makeStyles,
  useTheme,
} from '@material-ui/core';
import React, { useState } from 'react';
import CloseIcon from '@material-ui/icons/Close';
import {
  WbCardActionButton,
  useSelectorsContext,
} from '@agilelab/plugin-wb-platform';
import {
  snakeCaseToTitleCase,
  Environment,
} from '@agilelab/plugin-wb-platform-common';
import { Action } from './types';

const useStyles = makeStyles(theme => ({
  success: {
    background: theme.palette.success.main,
    '&:hover': {
      background: theme.palette.success.main,
    },
  },
  error: {
    background: theme.palette.error.main,
    '&:hover': {
      background: theme.palette.error.main,
    },
  },
}));

interface DeployUndeployDialogComponentProps {
  open: { state: boolean; action?: Action; version?: string };
  onClose: (env?: Environment, provisionAndNavigate?: boolean) => void;
  envList?: Environment[];
}

export function DeployUndeployDialogComponent({
  open,
  onClose,
  envList,
}: DeployUndeployDialogComponentProps): JSX.Element {
  const { state, action, version } = open;
  const { environmentList } = useSelectorsContext();
  const [env, setEnv] = useState<string | undefined>(
    envList?.length === 1 ? envList[0].name : undefined,
  );
  const theme = useTheme();
  const classes = useStyles();

  return (
    <Dialog
      open={state}
      onClick={e => {
        e.stopPropagation();
      }}
    >
      <DialogTitle style={{ backgroundColor: theme.palette.bkg.primary }}>
        <Typography> Quick Provisioning</Typography>
        <IconButton
          size="small"
          onClick={e => {
            e.stopPropagation();
            onClose();
          }}
          aria-label="close"
          style={{
            position: 'absolute',
            right: 16,
            top: 16,
            color: theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Box display="flex" flexDirection="column" style={{ gap: '16px' }}>
          <Typography variant="body1">
            You choose to{' '}
            <span style={{ fontWeight: 600 }}>
              {action} V{version}
            </span>
          </Typography>
          <FormControl>
            <Select
              displayEmpty
              variant="outlined"
              style={{
                width: '50%',
              }}
              renderValue={_ => (
                <Typography>
                  {snakeCaseToTitleCase(env ?? 'Select Environment')}
                </Typography>
              )}
              value={env}
              onChange={(e: React.ChangeEvent<{ value: unknown }>) => {
                setEnv(e.target.value as string);
                e.preventDefault();
                e.stopPropagation();
              }}
            >
              {envList?.map(e => (
                <MenuItem value={e.name}>
                  {snakeCaseToTitleCase(e.name)}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Divider />

          <Typography>
            Selecting this type of provisioning the basic settings will be
            applied. For more control over the provisioning process go to the
            release details.
          </Typography>
        </Box>
      </DialogContent>
      <DialogActions>
        <WbCardActionButton
          color="primary"
          size="small"
          onClick={event => {
            event.preventDefault();
            event.stopPropagation();
            onClose();
          }}
          variant="outlined"
          label="Cancel"
        />
        <WbCardActionButton
          disabled={!env}
          size="small"
          onClick={event => {
            const environment = environmentList.find(e => e.name === env);
            onClose(environment, true);
            event.preventDefault();
            event.stopPropagation();
          }}
          className={action === Action.Deploy ? classes.success : classes.error}
          label={snakeCaseToTitleCase(action ?? '')}
        />
      </DialogActions>
    </Dialog>
  );
}
