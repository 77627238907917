import { ReleaseAndDeployments } from '@agilelab/plugin-wb-builder-common';
import { UserEntityV1alpha1 } from '@backstage/catalog-model';
import { useApi } from '@backstage/core-plugin-api';
import { catalogApiRef } from '@backstage/plugin-catalog-react';
import { Box, Typography, useTheme } from '@material-ui/core';
import PersonIcon from '@material-ui/icons/Person';
import { Skeleton } from '@material-ui/lab';
import React, { useEffect, useState } from 'react';

interface Props {
  release: ReleaseAndDeployments;
}

const Loader: React.FC = () => {
  return (
    <Box display="flex" alignItems="center" style={{ gap: '8px' }}>
      <Skeleton variant="circle" width={24} height={24} />
      <Skeleton variant="text" height={15} width={130} />
    </Box>
  );
};

export const ReleaseCreatedBy: React.FC<Props> = ({ release }) => {
  const theme = useTheme();
  const catalogApi = useApi(catalogApiRef);
  const [loading, setLoading] = useState<boolean>(false);
  const [creator, setCreator] = useState<UserEntityV1alpha1 | undefined>();

  useEffect(() => {
    const creatorRef = release.metadata?.creatorRef;

    if (creatorRef) {
      setLoading(true);
      catalogApi
        .getEntityByRef(creatorRef)
        .then(e => {
          setCreator(e as UserEntityV1alpha1);
        })
        .finally(() => {
          setLoading(false);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading) return <Loader />;

  return (
    <Box display="flex" alignItems="center" style={{ gap: '8px' }}>
      <Box
        style={{
          height: '24px',
          width: '24px',
          borderRadius: '100%',
          border: `1px solid ${theme.palette.grey[300]}`,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        {creator?.spec?.profile?.picture ? (
          <img
            alt="user"
            src={creator?.spec?.profile?.picture}
            height="100%"
            width="100%"
            style={{ borderRadius: '100%' }}
          />
        ) : (
          <PersonIcon fontSize="small" />
        )}
      </Box>

      <Typography>
        {creator?.spec?.profile?.displayName || 'Unknown'}
      </Typography>
    </Box>
  );
};
