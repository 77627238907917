import { TableContentLoader, WbTable } from '@agilelab/plugin-wb-platform';
import React from 'react';
import { DeploymentPreview } from '../types';
import {
  Box,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  useTheme,
} from '@material-ui/core';
import { snakeCaseToTitleCase } from '@agilelab/plugin-wb-platform-common';
import { ComponentDependencies } from '../ProvisioningCard/ComponentsTable/ComponentDependencies';
import { DeploymentPreviewChip } from './DeploymentPreviewChip';
import {
  DeploymentUnitStatus,
  WitboostSubcomponent,
} from '@agilelab/plugin-wb-builder-common';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import { SubComponentRow } from './SubComponentRow';

interface Props {
  rows: Array<DeploymentPreview>;
  loading: boolean;
}

export const DeploymentPreviewTable: React.FC<Props> = ({ rows, loading }) => {
  const theme = useTheme();
  const headStyle = { style: { fontWeight: 500 } };

  return (
    <WbTable
      stickyHeader
      components={{
        tableLoader: {
          loading: false,
        },
        tableHeader: (
          <TableHead>
            <TableRow>
              <TableCell {...headStyle}>Name</TableCell>
              <TableCell {...headStyle}>Type</TableCell>
              <TableCell {...headStyle}>Dependencies</TableCell>
              <TableCell {...headStyle}>Current State</TableCell>
              <TableCell {...headStyle}>New State</TableCell>
              <TableCell {...headStyle}>Action</TableCell>
            </TableRow>
          </TableHead>
        ),
        tableContent: {
          body: (
            <TableBody>
              {loading && <TableContentLoader rows={10} tableCells={6} />}

              {!loading &&
                rows.map((preview, i) => (
                  <>
                    <TableRow key={i}>
                      <TableCell>
                        <Typography variant="body2" style={{ fontWeight: 700 }}>
                          {preview.name}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="body2">
                          {snakeCaseToTitleCase(preview.kind)}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <ComponentDependencies preview={preview} />
                      </TableCell>
                      <TableCell>
                        <DeploymentPreviewChip
                          status={preview.status}
                          version={preview.statusVersion}
                        />
                      </TableCell>
                      <TableCell>
                        <Box
                          style={{
                            display: 'flex',
                            gap: '16px',
                            alignItems: 'center',
                            marginLeft: '-32px',
                          }}
                        >
                          <ArrowForwardIcon
                            htmlColor={theme.palette.accent.main}
                          />
                          <DeploymentPreviewChip
                            status={
                              preview.newState as unknown as DeploymentUnitStatus
                            }
                          />
                        </Box>
                      </TableCell>
                      <TableCell>
                        <Typography variant="body2" style={{ fontWeight: 700 }}>
                          {preview.action &&
                            snakeCaseToTitleCase(preview.action)}
                        </Typography>
                      </TableCell>
                    </TableRow>

                    {(preview.components || []).map(
                      (subComponent: WitboostSubcomponent) => (
                        <SubComponentRow
                          keyProp={subComponent.name}
                          subComponent={subComponent}
                          componentPreview={preview}
                        />
                      ),
                    )}
                  </>
                ))}
            </TableBody>
          ),
        },
      }}
      styles={{
        container: { height: '100%' },
      }}
    />
  );
};
