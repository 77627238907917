import { parseNunjucks } from '@agilelab/plugin-wb-platform-common';
import { configApiRef, useApi } from '@backstage/core-plugin-api';
import { Chip, ChipProps, Theme, Tooltip, makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import React from 'react';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    background: `${theme.palette.softened.primary}`,
    '&:hover': {
      background: `${theme.palette.softened.primary}`,
    },
  },
  clickable: {
    '&:focus': {
      background: `${theme.palette.softened.primary}`,
    },
  },
  link: {
    textDecoration: 'underline',
  },
  label: {
    whiteSpace: 'pre',
  },
}));

export enum TagKind {
  generic = 'generic',
  schema = 'schema',
}

export interface Tag {
  tagFQN: string;
  description?: string;
  labelType?: string;
  state?: string;
  href?: string;
}

type WbTagProps = ChipProps & {
  tag: Tag;
  kind?: TagKind;
  tooltip?: boolean;
};

export const WbTag: React.FC<WbTagProps> = ({
  tag,
  kind = TagKind.generic,
  tooltip = true,
  onClick,
  ...props
}) => {
  const classes = useStyles();
  const configApi = useApi(configApiRef);
  const displayTemplate = configApi.getOptionalString(
    `mesh.mapping.tag.${kind}.display`,
  );
  const tooltipTemplate = configApi.getOptionalString(
    `mesh.mapping.tag.${kind}.tooltip`,
  );

  function getTagDisplayValue<T>(
    inputTag: Tag,
    template: string | undefined,
    fallback: (t: Tag) => T,
  ) {
    const displayed = parseNunjucks(template, inputTag);
    if (!displayed) {
      return fallback(inputTag);
    }
    return displayed;
  }

  const chip = (
    <Chip
      label={getTagDisplayValue(tag, displayTemplate, t => t.tagFQN)}
      size="small"
      classes={{
        root: classes.root,
        clickable: classes.clickable,
        label: tag.href ? clsx(classes.label, classes.link) : classes.label,
      }}
      clickable={Boolean(tag.href) || Boolean(onClick)}
      onClick={e => {
        if (tag.href) {
          e.preventDefault();
          e.stopPropagation();
          window.open(tag.href);
        } else onClick?.(e);
      }}
      {...props}
    />
  );

  const tooltipContent =
    getTagDisplayValue(tag, tooltipTemplate, t => t.description) ??
    getTagDisplayValue(tag, displayTemplate, t => t.tagFQN);

  return tooltip ? (
    <Tooltip title={tooltipContent} interactive>
      {chip}
    </Tooltip>
  ) : (
    chip
  );
};
