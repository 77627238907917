import { Box, Button, ButtonProps } from '@material-ui/core';
import React from 'react';

interface Props {
  clear: ButtonProps;
  selectAll: ButtonProps;
  containerStyle?: React.CSSProperties;
}

export const EnumButtons = ({ clear, selectAll, containerStyle }: Props) => {
  return (
    <Box
      style={{
        display: 'flex',
        gap: '8px',
        padding: '8px 16px 0px 16px',
        ...containerStyle,
      }}
    >
      <Button
        variant="text"
        color="primary"
        style={{ textDecoration: 'underline', padding: 0 }}
        {...selectAll}
      >
        Select All
      </Button>
      <Button
        variant="text"
        color="primary"
        style={{ textDecoration: 'underline', padding: 0 }}
        {...clear}
      >
        Clear
      </Button>
    </Box>
  );
};
