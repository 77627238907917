import {
  ConfirmDialog,
  customAlertApiRef,
  WbCardActionButton,
  WbLink,
} from '@agilelab/plugin-wb-platform';
import { useApi, useRouteRef } from '@backstage/core-plugin-api';
import React, { useState } from 'react';
import { Tooltip, Typography } from '@material-ui/core';
import { useEditorPageContext } from './context/useEditorPageContext';
import { panelCatalogApiRef } from '../../api';
import useAsyncFn from 'react-use/lib/useAsyncFn';
import { useNavigate } from 'react-router';
import { releaseDetailRouteRef } from '../../routes';
import { generateUpdateTooltip, getReleaseInfo } from '../../utils';
import { usePermission } from '@backstage/plugin-permission-react';
import { builderDpCommitPermission } from '@agilelab/plugin-wb-rbac-common';
import { stringifyEntityRef } from '@backstage/catalog-model';

interface Props {
  variant?: 'text' | 'outlined' | 'contained' | undefined;
}

export const UpdateButton: React.FC<Props> = ({ variant }) => {
  const [openDialog, setOpenDialog] = useState(false);
  const {
    entity,
    setVersion,
    addRelease,
    fetchDescriptor,
    entitiesAndBranches,
    setCurrRelease,
    descriptorState,
    actionError,
    processorErrors,
  } = useEditorPageContext();
  const panelCatalog = useApi(panelCatalogApiRef);
  const alertApi = useApi(customAlertApiRef);

  const navigate = useNavigate();
  const releaseDetailRoute = useRouteRef(releaseDetailRouteRef);

  const { allowed: canCommit } = usePermission({
    permission: builderDpCommitPermission,
    resourceRef: stringifyEntityRef({
      kind: entity?.kind ?? 'system',
      namespace: 'default',
      name: entity?.metadata.name ?? '',
    }),
  });

  const [shapshotState, commitSnapshot] = useAsyncFn(async () => {
    try {
      const entities = Object.entries(entitiesAndBranches).map(
        ([key, value]) => ({ entityRef: key, branch: value }),
      );
      const newRelease = await panelCatalog.commitSnapshot(
        entity.metadata.name,
        entity.kind,
        entities,
      );
      setVersion?.(newRelease.metadata.version);
      setCurrRelease(newRelease);
      addRelease(newRelease);

      const { kind, namespace, name } = getReleaseInfo(newRelease);

      await fetchDescriptor();
      alertApi.post({
        actions: (
          <WbLink
            route={releaseDetailRoute({ kind, namespace, name })}
            text={`Open V${newRelease?.metadata?.version}`}
            navigate={navigate}
          />
        ),
        message: 'Draft-Release updated.',
        severity: 'success',
      });
    } catch (error) {
      alertApi.post({
        error,
        severity: 'error',
      });
    } finally {
      setOpenDialog(false);
    }
  }, [
    alertApi,
    entity.metadata.name,
    panelCatalog,
    setVersion,
    entitiesAndBranches,
  ]);

  return (
    <>
      <Tooltip
        title={generateUpdateTooltip(
          canCommit,
          actionError,
          descriptorState.loading,
          false,
          processorErrors,
        )}
      >
        <span>
          <WbCardActionButton
            label="Update draft release"
            color="primary"
            size="small"
            variant={variant}
            disabled={
              !canCommit ||
              !!actionError ||
              descriptorState.loading ||
              !!processorErrors?.length
            }
            onClick={() => setOpenDialog(true)}
          />
        </span>
      </Tooltip>
      <ConfirmDialog
        title="A draft release already exists!"
        description={
          <>
            <Typography>
              By updating you will lose the current draft release and a new one
              will be created.
            </Typography>
            <Typography style={{ fontWeight: '700' }}>
              Are you sure you want to proceed with the update?
            </Typography>
          </>
        }
        open={openDialog}
        onConfirm={commitSnapshot}
        onClose={() => setOpenDialog(false)}
        inProgress={shapshotState.loading}
        confirmButtonText="Yes, update"
      />
    </>
  );
};
