import React, { useCallback, useEffect, useState } from 'react';
import { useApi } from '@backstage/core-plugin-api';
import useAsyncFn, { AsyncState } from 'react-use/lib/useAsyncFn';
import { panelCatalogApiRef } from '../../../api';
import { Entity } from '@backstage/catalog-model';
import { builderDpDeployPermission } from '@agilelab/plugin-wb-rbac-common';
import { stringifyEntityRef } from '@backstage/catalog-model';
import {
  ReleaseAndDeployments,
  ReleaseEntityV1alpha1,
} from '@agilelab/plugin-wb-builder-common';
import { ReleaseFilterBy, ReleaseFilters } from '../types';
import {
  AsyncPermissionResults,
  customAlertApiRef,
  usePermissions,
  useSelectorsContext,
} from '@agilelab/plugin-wb-platform';
import { Environment } from '@agilelab/plugin-wb-platform-common';
import { ProcessorError } from '../../../utils';

const buildFilters = (filters: ReleaseFilters): ReleaseFilterBy[] => {
  return Object.keys(filters)
    .filter(key => Boolean(filters[key as keyof ReleaseFilters]))
    .reduce((acc: ReleaseFilterBy[], curr) => {
      const key = curr as keyof ReleaseFilters;

      switch (key) {
        case 'createdBy':
          return [
            ...acc,
            {
              by: 'creatorRef',
              values: (filters[key] || []).map(f => f.ref) || [],
            },
          ];
        case 'deploymentStatus':
          return [...acc, { by: curr, values: filters[key] || [] }];
        case 'update': {
          const start = filters[key]?.startDate?.toISOString();
          const end = filters[key]?.endDate?.toISOString();
          const values = [];
          if (start) values.push(start);
          if (end) values.push(end);
          return [...acc, { by: 'createdAt', values }];
        }
        default:
          return acc;
      }
    }, []);
};

export type ReleasePageContextProps = {
  entity: Entity;
  relatedEntities: Entity[] | undefined;

  fetchReleases: () => Promise<ReleaseAndDeployments[]>;
  releasesState: AsyncState<ReleaseAndDeployments[]>;

  filters: ReleaseFilters;
  changeFilters: <K extends keyof ReleaseFilters>(
    key: K,
    value: ReleaseFilters[K],
  ) => void;

  resetFilters: () => void;

  deployAll: (
    release: ReleaseEntityV1alpha1,
    environment: Environment,
  ) => Promise<any>;
  undeployAll: (
    release: ReleaseEntityV1alpha1,
    environment: Environment,
  ) => Promise<any>;

  isCommitting: boolean;
  setIsCommitting: React.Dispatch<React.SetStateAction<boolean>>;

  isPromoting: boolean;
  setIsPromoting: React.Dispatch<React.SetStateAction<boolean>>;

  isReleasing: boolean;
  setIsReleasing: React.Dispatch<React.SetStateAction<boolean>>;

  isLoading: boolean;

  permissions?: AsyncPermissionResults;

  releases: ReleaseAndDeployments[];
  setReleases: React.Dispatch<React.SetStateAction<ReleaseAndDeployments[]>>;
  addRelease: (release: ReleaseAndDeployments) => void;

  processorErrors?: ProcessorError[];
};

export const ReleasePageContext = React.createContext<ReleasePageContextProps>(
  {} as ReleasePageContextProps,
);

interface Props {
  children?: React.ReactNode;
  entity: Entity;
  setVersion: React.Dispatch<React.SetStateAction<string>>;
  relatedEntities: Entity[] | undefined;
  processorErrors?: ProcessorError[];
}

export const ReleasePageContextProvider: React.FC<Props> = ({
  entity,
  relatedEntities,
  setVersion,
  children,
  processorErrors,
}) => {
  const alertApi = useApi(customAlertApiRef);
  const panelCatalogApi = useApi(panelCatalogApiRef);
  const [releases, setReleases] = useState<ReleaseAndDeployments[]>([]);
  const [filters, setFilters] = useState<ReleaseFilters>({});
  const [isCommitting, setIsCommitting] = useState<boolean>(false);
  const [isPromoting, setIsPromoting] = useState<boolean>(false);
  const [isReleasing, setIsReleasing] = useState<boolean>(false);
  const { environmentList } = useSelectorsContext();

  const permissions = usePermissions([
    ...environmentList.map(e => ({
      permission: builderDpDeployPermission(e.name),
      resourceRef: stringifyEntityRef({
        kind: entity?.kind ?? 'system',
        namespace: 'default',
        name: entity?.metadata.name ?? '',
      }),
    })),
  ]);

  const [releasesState, fetchReleases] = useAsyncFn(async () => {
    return panelCatalogApi.fetchReleases(
      entity.metadata.name,
      buildFilters(filters),
    );
  }, [entity, filters]);

  useEffect(() => {
    fetchReleases();
  }, [fetchReleases]);

  useEffect(() => {
    if (releasesState.value) {
      setReleases(releasesState.value);
    }
  }, [releasesState]);

  const deployAll = useCallback(
    async (release: ReleaseEntityV1alpha1, env: Environment) => {
      try {
        const deployResponse = await panelCatalogApi.deployRelease(
          release.metadata.name,
          release.metadata.projectName ?? release.metadata.dataProductName,
          release.metadata.projectKind ?? 'system',
          env.name,
        );

        if (deployResponse.errors) {
          throw new Error(JSON.stringify(deployResponse.errors));
        }
      } catch (error) {
        alertApi.post({
          error,
          severity: 'error',
        });
      }
    },
    [panelCatalogApi, alertApi],
  );

  const undeployAll = useCallback(
    async (release: ReleaseEntityV1alpha1, env: Environment) => {
      try {
        const undeployResponse = await panelCatalogApi.undeployRelease(
          release.metadata.name,
          release.metadata.projectName ?? release.metadata.dataProductName,
          release.metadata.projectKind ?? 'system',
          env.name,
        );

        if (undeployResponse.errors) {
          throw new Error(JSON.stringify(undeployResponse.errors));
        }
      } catch (error) {
        alertApi.post({
          error,
          severity: 'error',
        });
      }
    },
    [alertApi, panelCatalogApi],
  );

  return (
    <ReleasePageContext.Provider
      value={{
        entity,
        relatedEntities,
        releasesState,
        fetchReleases,

        filters,
        changeFilters: <K extends keyof ReleaseFilters>(
          key: K,
          filterValue: ReleaseFilters[K],
        ) => {
          setFilters(f => ({ ...f, [key]: filterValue }));
        },

        resetFilters: () => {
          setFilters({});
        },

        deployAll,
        undeployAll,

        isCommitting,
        setIsCommitting,

        isPromoting,
        setIsPromoting,

        isReleasing,
        setIsReleasing,

        isLoading: isCommitting || isPromoting || isReleasing,
        permissions,

        releases,
        setReleases,
        addRelease: useCallback(
          (release: ReleaseAndDeployments) => {
            if (releases) {
              const index = releases.findIndex(
                r => r.metadata.name === release.metadata.name,
              );
              if (index > -1) {
                releases[index] = release;
                setReleases([...releases]);
              } else {
                setReleases([release, ...releases]);
              }

              setVersion(release.metadata.version);
            }
          },
          [releases, setVersion],
        ),
        processorErrors,
      }}
    >
      {children}
    </ReleasePageContext.Provider>
  );
};
