import {
  BranchesAndCommitUids,
  CatalogInfoFile,
  DagStatus,
  deconstructVersion,
  DeployStatusWithResult,
  EntityAndBranch,
  GetProvisioningStatusResponse,
  Log,
  PaginatedProvisioningPlansResponse,
  ProvisioningPlan,
  ProvisioningPreviewComponentResponse,
  ProvisioningPreviewResponse,
  ReleaseAndDeployments,
  ReleaseEntity,
  ReverseProvisioningPlansAndMerged,
  ReverseProvisioningStatus,
  TaskAction,
  TerminationMode,
  ValidationResponse,
  ValidationResponseStatus,
  WitboostEntity,
} from '@agilelab/plugin-wb-builder-common';
import { CustomAlertApi } from '@agilelab/plugin-wb-platform';
import { handleFailedResponse } from '@agilelab/plugin-wb-platform-common';
import {
  createApiRef,
  DiscoveryApi,
  FetchApi,
} from '@backstage/core-plugin-api';
import { DEFAULT_NAMESPACE, Entity } from '@backstage/catalog-model';
import { isBefore, parseISO } from 'date-fns';
import * as yaml from 'yaml';
import {
  FilterBy,
  ProvisioningRequest,
} from '@agilelab/plugin-wb-builder-backend';

export declare type PanelCatalogRequestOptions = {
  token?: string;
};

export type NewVersion = {
  projectName: string;
  version: string;
};

export interface PanelCatalogApi {
  validateDescriptor(
    descriptor: string,
    projectName: string,
    projectKind?: string,
  ): Promise<ValidationResponse>;

  validateDescriptorAsync(
    descriptor: string,
    projectName: string,
    projectKind?: string,
  ): Promise<string>;

  getValidateDescriptorStatus(token: string): Promise<ValidationResponseStatus>;

  fetchEnvironments(options?: PanelCatalogRequestOptions): Promise<any[]>;

  fetchPreviewDescriptor(
    projectName: string,
    environment: string,
    kind?: string,
    entitiesAndBranches?: EntityAndBranch[],
  ): Promise<string>;

  fetchCatalogInfo(
    component: Entity,
    branch?: string,
    env?: string,
  ): Promise<CatalogInfoFile>;

  /**
   * Pushes a component's catalog info to its own host git repository
   * @param entity of witboost
   * @param content new catalog info file content
   * @param commitMessage
   * @param targetBranch it has to be an existing branch. default: branch of the component's catalog info
   * @param headCommit id of the commit from which to start the push operation.
   * @param options
   * @throws {GitRepositoryError} if the catalog info file has been modified on `targetBranch` after `headCommit`
   */
  pushCatalogInfo(
    entity: WitboostEntity,
    content: string,
    commitMessage: string,
    targetBranch?: string,
    headCommit?: string,
  ): Promise<void>;

  executeReverseProvisioning(
    useCaseTemplateId: string,
    infrastructureTemplateIds: string[],
    environment: string,
    params?: Record<string, any>,
    catalogInfo?: string,
  ): Promise<string>;

  fetchReleasePreviewDescriptor(
    releaseEntityName: string,
    environment: string,
  ): Promise<string>;

  fetchReleases(
    projectName: string,
    filterBy?: FilterBy,
  ): Promise<ReleaseAndDeployments[]>;

  createSnapshot(
    projectName: string,
    projectKind?: string,
    entitiesAndBranches?: EntityAndBranch[],
  ): Promise<ReleaseEntity>;

  commitSnapshot(
    projectName: string,
    projectKind: string,
    entitiesAndBranches?: EntityAndBranch[],
  ): Promise<ReleaseEntity>;

  promoteToRelease(
    releaseEntityName: string,
    projectName: string,
    projectKind?: string,
  ): Promise<ReleaseEntity>;

  deployRelease(
    releaseEntityName: string,
    projectName: string,
    projectKind: string,
    environment: string,
  ): Promise<any>;

  undeployRelease(
    releaseEntityName: string,
    projectName: string,
    projectKind: string,
    environment: string,
  ): Promise<any>;

  getDeployStatus(
    deployId: string,
    alertApi: CustomAlertApi,
  ): Promise<DeployStatusWithResult>;

  getReverseProvisioningStatus(
    reverseProvisioningId: string,
    alertApi: CustomAlertApi,
  ): Promise<ReverseProvisioningStatus>;

  terminateReverseProvisioning(reverseProvisioningId: string): Promise<void>;

  getReverseProvisioningPlansAndMerged(
    reverseProvisioningId: string,
    catalogInfo: string,
    alertApi: CustomAlertApi,
  ): Promise<ReverseProvisioningPlansAndMerged>;

  /**
   * @param includeDescriptors whether to include the data product descriptor along with other task details
   */
  getProvisioningPlan(deployId: string): Promise<ProvisioningPlan>;

  /**
   * @param includeDescriptors whether to include the data product descriptor along with other task details
   */
  getProvisioningPlansByEntityIdAndEnvironment(
    dpId: string,
    environment: string,
    includeDescriptors: boolean,
    includeSnapshot?: boolean,
    filters?: {
      version?: string;
      operations?: TaskAction[];
      offset?: number;
      limit?: number;
    },
  ): Promise<PaginatedProvisioningPlansResponse>;

  newEntityVersion(
    projectName: string,
    projectKind: string,
    version: string,
  ): Promise<NewVersion>;

  getLogs(deployId: string, taskId?: string): Promise<Log[]>;

  getDeploymentUnitStatus(
    deploymentUnitId: string,
    environment: string,
    includeDescriptor?: boolean,
  ): Promise<GetProvisioningStatusResponse>;

  terminateProvisioning(
    deploymentUnitId: string,
    environment: string,
    mode: TerminationMode,
  ): Promise<any>;

  getBranches(
    repoUrl: string,
    searchBranch?: string,
  ): Promise<BranchesAndCommitUids>;

  getDeploymentUnitsProvisioningPreview(
    deploymentUnitId: string,
    environment: string,
    provisioningRequest: ProvisioningRequest,
  ): Promise<ProvisioningPreviewResponse>;

  getComponentDeploymentUnitsProvisioningPreview(
    deploymentUnitId: string,
    componentId: string,
    environment: string,
    includeDescriptor: boolean,
  ): Promise<ProvisioningPreviewComponentResponse>;

  partialProvision(
    deploymentUnitId: string,
    environment: string,
    provisioningRequest: ProvisioningRequest,
    unsafe: boolean,
  ): Promise<ProvisioningPreviewResponse>;
}

export const panelCatalogApiRef = createApiRef<PanelCatalogApi>({
  id: 'wb-builder-catalog-panel',
});

/**
 * Note that the methods of this client will throw errors in case there is
 * an error generated by internal logics.
 * Handle the errors thrown in the caller logic.
 */
export class PanelCatalogClient implements PanelCatalogApi {
  private readonly discoveryApi: DiscoveryApi;
  private readonly fetchApi: FetchApi;

  constructor(options: { discoveryApi: DiscoveryApi; fetchApi: FetchApi }) {
    this.discoveryApi = options.discoveryApi;
    this.fetchApi = options.fetchApi;
  }

  async getProvisioningPlansByEntityIdAndEnvironment(
    dpId: string,
    environment: string,
    includeDescriptors: boolean,
    includeSnapshot?: boolean,
    filters?: {
      version?: string;
      operations?: TaskAction[];
      offset?: number;
      limit?: number;
    },
  ): Promise<PaginatedProvisioningPlansResponse> {
    const baseUrl = await this.discoveryApi.getBaseUrl('builder');

    const params = new URLSearchParams({
      ['data-product-id']: dpId,
      environments: environment,
      ['include-snapshot']: Boolean(includeSnapshot).toString(),
      ['include-descriptors']: Boolean(includeDescriptors).toString(),
      ...(filters?.version ? { version: filters.version } : {}),
      ...(filters?.operations
        ? { operations: filters.operations.toString() }
        : {}),
      ...(filters?.offset !== undefined
        ? { offset: filters.offset.toString() }
        : {}),
      ...(filters?.limit ? { limit: filters.limit.toString() } : {}),
    });
    const response = await this.fetchApi.fetch(
      `${baseUrl}/provisioningplan?${params}`,
      {
        headers: {
          'Content-Type': 'application/json',
        },
      },
    );

    await handleFailedResponse(response);

    return await response.json();
  }

  async validateDescriptor(
    descriptor: string,
    projectName: string,
    projectKind?: string,
  ): Promise<ValidationResponse> {
    const baseUrl = await this.discoveryApi.getBaseUrl('builder');
    const response = await this.fetchApi.fetch(
      `${baseUrl}/dataproducts/${projectName}/validate`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          descriptor: JSON.stringify(yaml.parse(descriptor)),
          projectKind: projectKind ?? 'System',
        }),
      },
    );

    await handleFailedResponse(response);

    return response.json();
  }

  async validateDescriptorAsync(
    descriptor: string,
    projectName: string,
    projectKind?: string,
  ): Promise<string> {
    const baseUrl = await this.discoveryApi.getBaseUrl('builder');
    const response = await this.fetchApi.fetch(
      `${baseUrl}/v2/dataproducts/${projectName}/validate`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          descriptor: JSON.stringify(yaml.parse(descriptor)),
          projectKind: projectKind ?? 'System',
        }),
      },
    );

    await handleFailedResponse(response);

    return response.json();
  }

  async getValidateDescriptorStatus(
    token: string,
  ): Promise<ValidationResponseStatus> {
    const baseUrl = await this.discoveryApi.getBaseUrl('builder');
    const response = await this.fetchApi.fetch(
      `${baseUrl}/v2/validate/${token}/status`,
      {
        headers: {},
      },
    );

    await handleFailedResponse(response);
    return await response.json();
  }

  async newEntityVersion(
    projectName: string,
    projectKind: string,
    version: string,
  ): Promise<NewVersion> {
    const baseUrl = await this.discoveryApi.getBaseUrl('builder');
    const majorVersion = deconstructVersion(version)?.major + 1;

    const queryParams = new URLSearchParams({
      entityName: projectName,
      version: majorVersion.toString(),
      projectKind: projectKind ?? 'System',
    });
    const response = await this.fetchApi.fetch(
      `${baseUrl}/new_version?${queryParams.toString()}`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
      },
    );

    await handleFailedResponse(response);

    return response.json();
  }

  async fetchEnvironments(): Promise<any[]> {
    const baseUrl = await this.discoveryApi.getBaseUrl('builder');
    const response = await this.fetchApi.fetch(`${baseUrl}/environments`, {
      method: 'GET',
      headers: {},
    });

    await handleFailedResponse(response);

    return response.json();
  }

  async fetchReleases(
    projectName: string,
    filterBy?: FilterBy,
  ): Promise<ReleaseAndDeployments[]> {
    const baseUrl = await this.discoveryApi.getBaseUrl('builder');
    const response = await this.fetchApi.fetch(
      `${baseUrl}/releases?${new URLSearchParams({
        dataProductName: projectName,
        ...(filterBy ? { filters: JSON.stringify(filterBy) } : {}),
      })}`,
      {
        method: 'GET',
        headers: {},
      },
    );

    await handleFailedResponse(response);

    const items = (await response.json()) as ReleaseAndDeployments[];

    return items.sort((a, b) =>
      isBefore(parseISO(a.metadata.createdAt), parseISO(b.metadata.createdAt))
        ? 1
        : -1,
    );
  }

  async createSnapshot(
    projectName: string,
    projectKind?: string,
    entitiesAndBranches?: EntityAndBranch[],
  ): Promise<ReleaseEntity> {
    const baseUrl = await this.discoveryApi.getBaseUrl('builder');
    const queryParams = new URLSearchParams({
      dataproductEntityName: projectName,
      projectKind: projectKind ?? 'System',
    });

    const response = await this.fetchApi.fetch(
      `${baseUrl}/releases?${queryParams.toString()}`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ entitiesAndBranches }),
      },
    );

    await handleFailedResponse(response);

    return (await response.json()) as ReleaseEntity;
  }

  async commitSnapshot(
    projectName: string,
    projectKind: string,
    entitiesAndBranches?: EntityAndBranch[],
  ): Promise<ReleaseEntity> {
    const baseUrl = await this.discoveryApi.getBaseUrl('builder');
    const queryParams = new URLSearchParams({
      name: projectName,
      projectKind: projectKind ?? 'System',
    });

    const response = await this.fetchApi.fetch(
      `${baseUrl}/commit?${queryParams.toString()}`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ entitiesAndBranches }),
      },
    );

    await handleFailedResponse(response);

    return (await response.json()) as ReleaseEntity;
  }

  async promoteToRelease(
    releaseEntityName: string,
    projectName: string,
    projectKind?: string,
  ): Promise<ReleaseEntity> {
    const baseUrl = await this.discoveryApi.getBaseUrl('builder');
    const response = await this.fetchApi.fetch(
      `${baseUrl}/releases?${new URLSearchParams({
        releaseEntityName: releaseEntityName,
        dataproductEntityName: projectName,
        projectKind: projectKind ?? 'system',
      })}`,
      {
        method: 'PUT',
        headers: {},
      },
    );

    await handleFailedResponse(response);

    return (await response.json()) as ReleaseEntity;
  }

  async fetchPreviewDescriptor(
    projectName: string,
    environment: string,
    projectKind?: string,
    entitiesAndBranches?: EntityAndBranch[],
  ): Promise<string> {
    const baseUrl = await this.discoveryApi.getBaseUrl('builder');
    const queryParams = new URLSearchParams({
      dataProduct: projectName,
      projectKind: projectKind ?? 'System',
      environment,
    });

    const response = await this.fetchApi.fetch(
      `${baseUrl}/dataproducts/preview?${queryParams.toString()}`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ entitiesAndBranches }),
      },
    );

    await handleFailedResponse(response);

    return response.text();
  }

  async fetchCatalogInfo(
    entity: Entity,
    branch?: string,
    env?: string,
  ): Promise<CatalogInfoFile> {
    const baseUrl = await this.discoveryApi.getBaseUrl('builder');

    const queryParams = new URLSearchParams();
    if (branch) queryParams.set('branch', branch);
    if (env) queryParams.set('env', env);

    const response = await this.fetchApi.fetch(
      `${baseUrl}/${entity.kind}/${
        entity.metadata.namespace ?? DEFAULT_NAMESPACE
      }/${entity.metadata.name}/catalog-info?${queryParams.toString()}`,
      {
        method: 'GET',
        headers: {},
      },
    );

    await handleFailedResponse(response);

    return response.json();
  }

  async pushCatalogInfo(
    entity: WitboostEntity,
    content: string,
    commitMessage: string,
    targetBranch?: string,
    headCommit?: string,
  ): Promise<void> {
    const baseUrl = await this.discoveryApi.getBaseUrl('builder');
    const body = JSON.stringify({
      content: content,
      commitMessage: commitMessage,
      targetBranch: targetBranch,
      headCommit: headCommit,
    });
    const response = await this.fetchApi.fetch(
      `${baseUrl}/${entity.kind}/${
        entity.metadata.namespace ?? DEFAULT_NAMESPACE
      }/${entity.metadata.name}/catalog-info`,
      {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: body,
      },
    );
    await handleFailedResponse(response);
  }

  async fetchReleasePreviewDescriptor(
    releaseEntityName: string,
    environment: string,
  ): Promise<string> {
    const baseUrl = await this.discoveryApi.getBaseUrl('builder');

    const response = await this.fetchApi.fetch(
      `${baseUrl}/releases/preview?${new URLSearchParams({
        releaseEntityName,
        environment,
      })}`,
      {
        method: 'GET',
        headers: {},
      },
    );

    await handleFailedResponse(response);

    return response.text();
  }

  async executeReverseProvisioning(
    useCaseTemplateId: string,
    infrastructureTemplateIds: string[],
    environment: string,
    params?: Record<string, any>,
    catalogInfo?: string,
  ): Promise<string> {
    const body = JSON.stringify({
      useCaseTemplateId: useCaseTemplateId,
      infrastructureTemplateIds: infrastructureTemplateIds,
      params: params,
      environment: environment,
      catalogInfo: catalogInfo, // as json string
    });
    const baseUrl = await this.discoveryApi.getBaseUrl('builder');
    const response = await this.fetchApi.fetch(
      `${baseUrl}/reverse-provisioning`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: body,
      },
    );

    await handleFailedResponse(response);

    return await response.text();
  }

  async deployRelease(
    releaseEntityName: string,
    projectName: string,
    projectKind: string,
    environment: string,
  ): Promise<string> {
    const baseUrl = await this.discoveryApi.getBaseUrl('builder');
    const response = await this.fetchApi.fetch(
      `${baseUrl}/releases/${releaseEntityName}/deploy?${new URLSearchParams({
        releaseEntityName,
        dataproductEntityName: projectName,
        environment,
        projectKind,
      })}`,
      {
        method: 'POST',
        headers: {},
      },
    );

    await handleFailedResponse(response);

    return await response.text();
  }

  async undeployRelease(
    releaseEntityName: string,
    projectName: string,
    projectKind: string,
    environment: string,
  ): Promise<string> {
    const baseUrl = await this.discoveryApi.getBaseUrl('builder');
    const response = await this.fetchApi.fetch(
      `${baseUrl}/releases/${releaseEntityName}/deploy?${new URLSearchParams({
        releaseEntityName,
        projectName,
        environment,
        projectKind,
      })}`,
      {
        method: 'DELETE',
        headers: {},
      },
    );

    return (await response.text()) as string;
  }

  async getDeployStatus(
    deployId: string,
    alertApi: CustomAlertApi,
  ): Promise<DeployStatusWithResult> {
    const baseUrl = await this.discoveryApi.getBaseUrl('builder');
    const response = await this.fetchApi.fetch(
      `${baseUrl}/deploy/${deployId}/status`,
      {
        headers: {},
      },
    );
    try {
      await handleFailedResponse(response);
      return await response.json();
    } catch (error) {
      alertApi.post({ error, severity: 'error' });
      return { status: DagStatus.FAILED, result: null };
    }
  }

  async getReverseProvisioningStatus(
    reverseProvisioningId: string,
    alertApi: CustomAlertApi,
  ): Promise<ReverseProvisioningStatus> {
    const baseUrl = await this.discoveryApi.getBaseUrl('builder');
    const response = await this.fetchApi.fetch(
      `${baseUrl}/reverse-provisioning/${reverseProvisioningId}/status`,
      {
        headers: {},
      },
    );
    try {
      await handleFailedResponse(response);
      return await response.json();
    } catch (error) {
      alertApi.post({ error, severity: 'error' });
      return { status: DagStatus.FAILED };
    }
  }

  async terminateReverseProvisioning(
    reverseProvisioningId: string,
  ): Promise<void> {
    const baseUrl = await this.discoveryApi.getBaseUrl('builder');
    await this.fetchApi.fetch(
      `${baseUrl}/reverse-provisioning/${reverseProvisioningId}/terminate`,
      {
        method: 'DELETE',
        headers: {},
      },
    );
  }

  async getReverseProvisioningPlansAndMerged(
    reverseProvisioningId: string,
    catalogInfo: string,
    alertApi: CustomAlertApi,
  ): Promise<ReverseProvisioningPlansAndMerged> {
    const baseUrl = await this.discoveryApi.getBaseUrl('builder');
    const response = await this.fetchApi.fetch(
      `${baseUrl}/reverse-provisioning/${reverseProvisioningId}/plans`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ catalogInfo: catalogInfo }),
      },
    );
    try {
      await handleFailedResponse(response);
      return await response.json();
    } catch (error) {
      alertApi.post({ error, severity: 'error' });
      return { environment: '', dag: { status: DagStatus.FAILED } };
    }
  }

  async getProvisioningPlan(deployId: string): Promise<ProvisioningPlan> {
    const baseUrl = await this.discoveryApi.getBaseUrl('builder');
    const response = await this.fetchApi.fetch(
      `${baseUrl}/deploy/${deployId}`,
      {
        headers: {},
      },
    );

    await handleFailedResponse(response);

    return await response.json();
  }

  async getLogs(deployId: string, taskId?: string): Promise<Log[]> {
    const searchParams = new URLSearchParams({ ...(taskId ? { taskId } : {}) });
    const baseUrl = await this.discoveryApi.getBaseUrl('builder');
    const response = await this.fetchApi.fetch(
      `${baseUrl}/logs/${deployId}?${searchParams}`,
      {
        headers: {},
      },
    );

    await handleFailedResponse(response);

    return await response.json();
  }

  async getDeploymentUnitStatus(
    deploymentUnitId: string,
    environment: string,
    includeDescriptor?: boolean,
  ): Promise<GetProvisioningStatusResponse> {
    const baseUrl = await this.discoveryApi.getBaseUrl('builder');
    const searchParams = new URLSearchParams({
      ...(includeDescriptor
        ? { ['include-descriptor']: String(includeDescriptor), environment }
        : { environment }),
    });
    const response = await this.fetchApi.fetch(
      `${baseUrl}/deployment-units/${deploymentUnitId}/provisioning-status?${searchParams}`,
      {
        headers: {},
      },
    );

    await handleFailedResponse(response);

    return await response.json();
  }

  async getBranches(
    repoUrl: string,
    searchBranch?: string,
  ): Promise<BranchesAndCommitUids> {
    const baseUrl = await this.discoveryApi.getBaseUrl('builder');
    const response = await this.fetchApi.fetch(
      `${baseUrl}/branches?${new URLSearchParams({
        repoUrl: repoUrl,
        ...(searchBranch ? { searchBranch } : {}),
      })}`,
      {
        method: 'GET',
        headers: {},
      },
    );

    await handleFailedResponse(response);

    return await response.json();
  }

  async getDeploymentUnitsProvisioningPreview(
    deploymentUnitId: string,
    environment: string,
    provisioningRequest: ProvisioningRequest,
  ): Promise<ProvisioningPreviewResponse> {
    const baseUrl = await this.discoveryApi.getBaseUrl('builder');
    const searchParams = new URLSearchParams({ environment });
    const response = await this.fetchApi.fetch(
      `${baseUrl}/deployment-units/${deploymentUnitId}/provisioning/preview?${searchParams}`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(provisioningRequest),
      },
    );

    await handleFailedResponse(response);

    return await response.json();
  }

  async getComponentDeploymentUnitsProvisioningPreview(
    deploymentUnitId: string,
    componentId: string,
    environment: string,
    includeDescriptor: boolean,
  ): Promise<ProvisioningPreviewComponentResponse> {
    const baseUrl = await this.discoveryApi.getBaseUrl('builder');
    const searchParams = new URLSearchParams({
      environment,
      ['include-descriptor']: String(includeDescriptor),
    });
    const response = await this.fetchApi.fetch(
      `${baseUrl}/deployment-units/${deploymentUnitId}/${componentId}/provisioning/preview?${searchParams}`,
      {
        headers: {},
      },
    );

    await handleFailedResponse(response);

    return await response.json();
  }

  async terminateProvisioning(
    deploymentUnitId: string,
    environment: string,
    mode?: TerminationMode,
  ): Promise<any> {
    const baseUrl = await this.discoveryApi.getBaseUrl('builder');
    const response = await this.fetchApi.fetch(
      `${baseUrl}/deployment-units/${deploymentUnitId}/provisioning?${new URLSearchParams(
        {
          environment,
          ...(mode ? { mode } : {}),
        },
      )}`,
      {
        method: 'DELETE',
        headers: {},
      },
    );
    return (await response.text()) as string;
  }

  async partialProvision(
    deploymentUnitId: string,
    environment: string,
    provisioningRequest: ProvisioningRequest,
    unsafe: boolean = false,
  ): Promise<ProvisioningPreviewResponse> {
    const baseUrl = await this.discoveryApi.getBaseUrl('builder');
    const searchParams = new URLSearchParams({
      environment,
      unsafe: String(unsafe),
    });
    const response = await this.fetchApi.fetch(
      `${baseUrl}/deployment-units/${deploymentUnitId}/provisioning?${searchParams}`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(provisioningRequest),
      },
    );

    await handleFailedResponse(response);

    return await response.json();
  }
}
