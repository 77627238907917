import { WitboostSubcomponent } from '../types';

/**
 * Checks for duplicated subcomponent names within a WitboostComponent entity.
 * @returns {string | undefined} Returns a string message if duplicates are found, otherwise undefined.
 * @param subcomponents to check
 */
export function errorMessageIfSubcomponentsDuplicates(
  subcomponents: WitboostSubcomponent[] | undefined,
): string | undefined {
  if (!subcomponents) return undefined;
  const namesMap = subcomponents.reduce<Record<string, number>>(
    (map, subcomponent) => {
      if (!map[subcomponent.name]) map[subcomponent.name] = 0;
      map[subcomponent.name] += 1;
      return map;
    },
    {},
  );

  const duplicates = Object.entries(namesMap).flatMap(([name, occurrences]) => {
    if (occurrences === 1) return [];
    return [name];
  });

  if (duplicates.length > 0) {
    return `Duplicated subcomponents' names found in the component entity: ${duplicates.join(
      ', ',
    )}`;
  }
  return undefined;
}

/**
 * If a (sub)component is consumable, but neither itself nor parent is shoppable, throw error.
 * @returns {string | undefined} Returns a string message if throws error, otherwise undefined.
 * @param componentOptions of the parent component
 * @param subcomponents to check
 *
 * @deprecated public component can now be consumable and not shoppable (and neither its parent)
 */
export function errorMessageIfConsumableButNotShoppable(
  componentOptions: { consumable?: boolean; shoppable?: boolean },
  subcomponents: WitboostSubcomponent[] | undefined,
): string | undefined {
  if (componentOptions.consumable && !componentOptions.shoppable)
    return `Parent component is consumable but not shoppable`;

  if (!subcomponents) return undefined;

  const consumableNotShoppableSubcomponentsNames = subcomponents
    .filter(component => component.consumable && !component.shoppable)
    .map(component => component.name);

  if (
    !componentOptions.shoppable &&
    consumableNotShoppableSubcomponentsNames.length > 0
  ) {
    return `The following subcomponents are consumable, but neither their parent nor themself are shoppable: ${consumableNotShoppableSubcomponentsNames.join(
      ', ',
    )}`;
  }

  return undefined;
}
