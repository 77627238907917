import { CatalogApi } from '@backstage/catalog-client';
import { GroupEntity, stringifyEntityRef } from '@backstage/catalog-model';
import { GroupEntityRef, UserEntityRef } from '../types';

/**
 * Fetches (recursively) all the groups and super-groups related to a specific user entityRefString. If the starting entityRefString is a group,
 * it fetches all the related super-groups (if any).
 * @param entityRefString: the starting entity ref
 * @param catalogApi
 * @param token
 * @returns a list of entity refs corresponding to the whole hierarchy of groups to which the starting user belongs to
 * @improvement https://witboost.atlassian.net/browse/WIT-1270
 */
export const resolveGroupsHierarchy = async (
  entityRefString: string,
  catalogApi: CatalogApi,
  options?: { token?: string },
): Promise<string[]> => {
  const entityRefKind = entityRefString.split(':')[0].toLowerCase();
  let currentGroups: string[];
  if (entityRefKind === 'user') {
    // if the entity ref kind is user, it takes alle the groups the users belongs to by using the 'spec.memberOf' attribute
    currentGroups = (await catalogApi.getEntityByRef(entityRefString))?.spec!
      .memberOf as string[];
  } else {
    // otherwise (if the entity ref kind is 'group'), it takes all the suprt-groups by using the 'spec.children' attribute
    currentGroups = (
      await catalogApi.getEntities(
        {
          filter: [{ 'spec.children': [entityRefString], kind: 'Group' }],
        },
        options,
      )
    ).items.map(item =>
      stringifyEntityRef({
        kind: item.kind,
        namespace: item.metadata.namespace,
        name: item.metadata.name,
      }),
    );
  }

  if (currentGroups.length) {
    return [
      entityRefString,
      ...[
        ...new Set(
          (
            await Promise.all(
              currentGroups.map(
                async group => await resolveGroupsHierarchy(group, catalogApi),
              ),
            )
          ).flat(),
        ),
      ],
    ];
  }

  return [entityRefString];
};

/**
 * Top-down recursive function that resolves all the members of a group, including the members of its sub-groups.
 * @param groupRef e.g. 'group:default/dev'
 * @param options - the catalogApi and the token
 * @returns a list of user entity refs e.g. ['user:default/name.surname_agilelab.it', 'user:default/name.surname2_agilelab.it']
 */
export async function resolveGroupMembersRecursively(
  groupRef: GroupEntityRef,
  options: {
    catalogApi: CatalogApi;
    token?: string;
  },
): Promise<UserEntityRef[]> {
  const groupEntity: GroupEntity | undefined =
    (await options.catalogApi.getEntityByRef(groupRef, {
      token: options.token,
    })) as GroupEntity | undefined;

  const userRefsPromises = groupEntity?.relations?.flatMap(async relation => {
    if (relation.type === 'hasMember') {
      const userRef = relation.targetRef;
      return [userRef];
    } else if (relation.type === 'parentOf') {
      const childGroupRef = relation.targetRef as GroupEntityRef;
      return resolveGroupMembersRecursively(childGroupRef, options);
    }
    return [];
  });

  const userRefs = userRefsPromises ? await Promise.all(userRefsPromises) : [];

  return Array.from(new Set<string>(userRefs.flat())) as UserEntityRef[];
}

import { groupRefSchema } from '../types';

export function isGroupRef(ref: string): boolean {
  return groupRefSchema.safeParse(ref).success;
}
