import nunjucks from 'nunjucks';

type Function = (...args: any[]) => any;

/**
 * Checks if every object in the array has a specific key with a specified value.
 *
 * @param arr - The array of objects to check.
 * @param key - The key to look for in each object.
 * @param value - The value to compare against.
 * @returns `true` if every object in the array has the key with the specified value, otherwise `false`.
 */
export function every(
  arr: Array<Record<string, any>>,
  key: string,
  value: any,
): boolean {
  return arr.every(obj => obj[key]?.toString() === value.toString());
}

/**
 * Checks if at least one object in the array have a specific key with a specified value.
 *
 * @param arr - The array of objects to check.
 * @param key - The key to look for in each object.
 * @param value - The value to compare against.
 * @returns `true` if some objects in the array have the key with the specified value, otherwise `false`.
 */
export function some(
  arr: Array<Record<string, any>>,
  key: string,
  value: any,
): boolean {
  return arr.some(obj => obj[key]?.toString() === value.toString());
}

/**
 * Adds an array of functions as filters to the provided Nunjucks environment.
 *
 * Each function's name will be used as the filter name.
 *
 * @param env - The Nunjucks environment to which the functions will be added as filters.
 * @param functions - An array of functions to be added as filters. Each function's name will be used as the filter name.
 *
 * @example
 * ```typescript
 * import * as nunjucks from 'nunjucks';
 *
 * const env = new nunjucks.Environment();
 *
 * function myFilter(value: string): string {
 *   return value.toUpperCase();
 * }
 *
 * addCustomFunctions(env, [myFilter]);
 *
 * // Now you can use {{ "test" | myFilter }} in your Nunjucks templates to get "TEST".
 * ```
 */
function addCustomFunctions(env: nunjucks.Environment, functions: Function[]) {
  functions.forEach(f => env.addFilter(f.name, f));
}

/**
 * Initializes a Nunjucks environment and adds custom filters.
 *
 * @returns The configured Nunjucks environment.
 */
export function initNunjucks(
  options?: nunjucks.ConfigureOptions,
): nunjucks.Environment {
  const env = nunjucks.configure(options ?? {});
  addCustomFunctions(env, [some, every]);
  return env;
}
