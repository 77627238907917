import { FieldValidation } from '@rjsf/core';
import { ApiHolder } from '@backstage/core-plugin-api';
import { RegexValidationRequest } from '../../../types';

export const isValidString = (value: unknown, regex: RegExp): boolean => {
  return typeof value === 'string' && regex.test(value);
};

export const regExPickerValidation = (
  value: string,
  validation: FieldValidation,
  _context: { apiHolder: ApiHolder },
  { validation: validationRequest }: { validation: RegexValidationRequest },
) => {
  if (
    value &&
    !isValidString(
      value ?? '',
      new RegExp(validationRequest.regularExpression, validationRequest.flags),
    )
  ) {
    validation.addError(
      `${
        validationRequest.errorMessage ?? 'Regular expression validation failed'
      }`,
    );
  }
};
