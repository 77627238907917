import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() =>
  createStyles({
    icon: {
      width: '30px',
      height: '30px',
    },
    animatedIcon: {
      animation: '$spinClockwise 2s linear infinite',
    },
    '@keyframes spinClockwise': {
      '0%': {
        transform: 'rotate(0deg)',
      },
      '100%': {
        transform: 'rotate(360deg)',
      },
    },
  }),
);
