import React, { useState } from 'react';
import { GovernanceSpecificResultComponent } from '@agilelab/plugin-wb-governance';
import { WbDrawer } from '@agilelab/plugin-wb-platform';

interface TestDetailDrawerProps {
  evaluationId: number | string;
  open: boolean;
  setOpen: () => void;
}

export const TestDetailDrawer: React.FC<TestDetailDrawerProps> = ({
  open,
  setOpen,
  evaluationId,
}) => {
  const [title, setTitle] = useState<React.ReactNode>();

  return (
    <WbDrawer open={open} setOpen={setOpen} title="Test Details">
      <>
        {title}
        {evaluationId && (
          <GovernanceSpecificResultComponent
            evaluationId={evaluationId}
            setTitle={setTitle}
          />
        )}
      </>
    </WbDrawer>
  );
};
