import {
  errorMessageIfSubcomponentsDuplicates,
  generateURNByKind,
  WitboostVersionedEntity,
} from '@agilelab/plugin-wb-builder-common';
import { Entity } from '@backstage/catalog-model';

function buildErrorMessage(
  message: string,
  currentMessage: string | undefined,
  suffix?: string,
) {
  return `${currentMessage ?? ''}${message}. ${suffix ?? ''}`;
}

/**
 * Checks for subcomponents errors within the provided descriptor.
 * @param {Record<string, any>} descriptor - The descriptor object containing components information.
 * @param {string | undefined} errorMessage - Optional error message to append to the default message.
 * @returns {string | undefined} Returns an error message if subcomponents have errors, otherwise undefined.
 */
export function checkSubcomponentsError(
  descriptor: Record<string, any>,
  errorMessage: string | undefined,
): string | undefined {
  const components = descriptor.components as Record<string, any>[];
  const errorMessagesList: (string | undefined)[] = [];
  for (const component of components) {
    errorMessagesList.push(
      errorMessageIfSubcomponentsDuplicates(component.components),
    );
  }
  const joinedErrors = errorMessagesList
    .filter(error => error !== undefined)
    .join('. ');

  if (joinedErrors !== '') return buildErrorMessage(joinedErrors, errorMessage);
  return undefined;
}

/**
 * Checks for data product name error between the provided descriptor and data product.
 * @param {Record<string, any>} descriptor - The descriptor object containing identifier information.
 * @param {Entity} dp - The data product entity to compare against.
 * @param {string | undefined} errorMessage - Optional error message to append to the default message.
 * @returns {string | undefined} Returns an error message if name mismatched, otherwise undefined.
 */
export function checkDpNameError(
  descriptor: Record<string, any>,
  dp: Entity,
  errorMessage: string | undefined,
): string | undefined {
  if (descriptor?.id !== generateURNByKind(dp?.metadata?.name, 'dp')) {
    return buildErrorMessage(
      `Use the same data product '${dp?.metadata?.name ?? ''}. `,
      errorMessage,
    );
  }
  return undefined;
}

/**
 * Checks for version error between the provided version and the expected version.
 * @param {Record<string, any>} descriptor - The descriptor object containing version information.
 * @param {WitboostVersionedEntity} dp - The entity with expected version information.
 * @param {string | undefined} version - The version to check against.
 * @param {string | undefined} errorMessage - Optional error message to append to the default message.
 * @returns {string | undefined} Returns an error message if version mismatched, otherwise undefined.
 */
export function checkVersionError(
  descriptor: Record<string, any>,
  dp: WitboostVersionedEntity,
  version: string | undefined,
  errorMessage: string | undefined,
): string | undefined {
  if (
    descriptor?.version?.replace('.0', '') !==
    (version?.replace('.0', '') ?? dp?.spec?.mesh?.version)
  )
    return buildErrorMessage(
      `Use the same version ${dp?.spec?.mesh?.version ?? ''}. `,
      errorMessage,
    );
  return undefined;
}
