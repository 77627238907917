import React, { useMemo } from 'react';
import { TextFieldProps } from '@material-ui/core';
import { configApiRef, useApi } from '@backstage/core-plugin-api';
import { snakeCaseToTitleCase } from '@agilelab/plugin-wb-platform-common';
import { WbMultiSelect, WbSelect } from '../WbTextField';

interface Props {
  onChange: (env: any) => void;
  multi?: boolean;
  value: string | string[];
  ref: React.Ref<unknown>;
  envs?: string[];
  label?: string;
}

export const SelectEnvironment = React.forwardRef(
  (
    props: Props & Omit<TextFieldProps, 'value' | 'onChange' | 'select'>,
    ref: React.Ref<any>,
  ) => {
    const { onChange, multi, value, envs, children, label, ...textFieldProps } =
      props;
    const handleChange = (event: React.ChangeEvent<any>) => {
      onChange(event.target.value);
    };
    const configApi = useApi(configApiRef);
    const fetchedEnvs = configApi.getStringArray('mesh.builder.environments');
    const displayedEnvs = useMemo(
      () => envs || fetchedEnvs,
      [envs, fetchedEnvs],
    );

    return multi && Array.isArray(value) ? (
      <WbMultiSelect
        {...textFieldProps}
        ref={ref}
        value={value}
        id="select-env"
        label={label ?? 'Environment'}
        options={displayedEnvs || []}
        getOptionLabel={env => snakeCaseToTitleCase(env)}
        onChange={handleChange}
      />
    ) : (
      <WbSelect
        {...textFieldProps}
        ref={ref}
        value={value}
        id="select-env"
        label={label ?? 'Environment'}
        options={displayedEnvs || []}
        getOptionLabel={env => snakeCaseToTitleCase(env)}
        onChange={handleChange}
      />
    );
  },
);
