import { gql } from '@apollo/client';
import { GOVERNANCE_POLICY_FRAGMENT } from '../../../fragments/governance-policy';
import { GOVERNANCE_RESULT_FRAGMENT } from '../../../fragments/governance-result';

export const GET_EVALUATION_RESULT_BY_ID = gql`
  query GET_EVALUATION_RESULT_BY_ID($resultId: bigint!) {
    cgp_evaluation_result_by_pk(id: $resultId) {
      ${GOVERNANCE_RESULT_FRAGMENT}
      governance_entity {
        ${GOVERNANCE_POLICY_FRAGMENT}
      }
    }
  }
`;
