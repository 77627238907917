/*
 * Copyright 2020 The Backstage Authors
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React from 'react';
import { Outlet, Navigate } from 'react-router';
import {
  AsyncEntityProvider,
  useAsyncEntity,
} from '@backstage/plugin-catalog-react';
import { useEntityFromUrl } from './useEntityFromUrl';
import { configApiRef, useApi } from '@backstage/core-plugin-api';
import { strCmpAll } from '../EntitySwitch/conditions';
import { Progress } from '@backstage/core-components';

function CatalogEntityPageContent() {
  const configApi = useApi(configApiRef);
  const hiddenKinds = configApi.getOptionalStringArray('catalog.hiddenKinds');
  const { entity, loading } = useAsyncEntity();

  if (loading) return <Progress />;

  if (strCmpAll(entity?.kind, hiddenKinds || [])) return <Navigate to="/" />;

  return <Outlet />;
}

/** @public */
export function CatalogEntityPage() {
  return (
    <AsyncEntityProvider {...useEntityFromUrl()}>
      <CatalogEntityPageContent />
    </AsyncEntityProvider>
  );
}
