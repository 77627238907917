import { useCallback, useMemo } from 'react';
import { useQueryParamState } from '@backstage/core-components';

export function useStorageParams<T>(
  key: string,
  defaultValues: T,
): [T, (newParams: T) => void] {
  const [params, setParams] = useQueryParamState<T>('filters');

  const storageParams: T = useMemo(() => {
    if (params) {
      return params;
    }

    try {
      const storageFilters = sessionStorage.getItem(key);
      return storageFilters ? JSON.parse(storageFilters) : defaultValues;
    } catch {
      return defaultValues;
    }
  }, [defaultValues, key, params]);

  const setStorageParams = useCallback(
    (newParams: T) => {
      try {
        sessionStorage.setItem(key, JSON.stringify(newParams));
        setParams(newParams);
      } catch {
        // eslint-disable-next-line no-console
        console.error(
          'Impossible set new query params inside session storage!',
        );
      }
    },
    [key, setParams],
  );

  return [storageParams, setStorageParams];
}
