export const formatAnalyzeError = (error: any) => {
  let simplifiedErrorMessage;
  try {
    simplifiedErrorMessage = JSON.parse(error.message).error.message;
  } catch (e) {
    // eslint-disable-next-line no-empty
  }

  return simplifiedErrorMessage ?? error?.data?.error?.message ?? error.message;
};
