import React from 'react';
import { useApi, errorApiRef } from '@backstage/core-plugin-api';
import { getCustomLogo } from './utils';
import { CustomLogoProps } from './types';
import { DefaultLogo } from './DefaultLogo';
import { CustomLogo } from './CustomLogo';
import { Skeleton } from '@material-ui/lab';
import DefaultIconLogo from './DefaultIconLogo';

type LogoProps = {
  full?: boolean;
};

export const Logo = ({ full = true }: LogoProps) => {
  const errorApi = useApi(errorApiRef);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [customLogo, setCustomLogo] = React.useState<
    CustomLogoProps | undefined
  >();

  const loadCustomLogo = () => {
    setLoading(true);
    getCustomLogo()
      .then(custom => {
        if (custom) setCustomLogo(custom);
      })
      .catch(e => {
        errorApi.post(e);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  React.useEffect(() => {
    loadCustomLogo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!full) return <DefaultIconLogo />;

  if (loading)
    return (
      <Skeleton style={{ minWidth: '190px' }} width="100%" height="100%" />
    );

  return customLogo ? <CustomLogo customLogo={customLogo} /> : <DefaultLogo />;
};
