export const GOVERNANCE_RESULT_FRAGMENT = `
id
outcome
resource_content
resource_display_name
resource_name
resource_type {
  name
  uuid
}
result
governance_entity_type
evaluation_report {
  creation_time
  update_time
}
governance_entity
`;
