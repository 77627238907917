import { gql } from '@apollo/client/core';

export const GET_ENVIRONMENTS = gql`
  query getEnvs {
    marketplace_Environment(order_by: { priority: asc }) {
      name
      id
      priority
    }
  }
`;
