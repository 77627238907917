import {
  InputPortDependencyEntity,
  OutputPortDependencyEntity,
  SystemDependenciesEntities,
  SystemDependencyEntity,
} from '@agilelab/plugin-wb-marketplace-common';
import { DependencyNode, DependencyNodeId } from '../components/types';

function parseDataProduct(input: SystemDependencyEntity): DependencyNode {
  return {
    name: input.name,
    id: input.id as any,
    domain: input.domain.at(0)?.data.name ?? 'Unassigned',
    type: 'main',
    displayName: input.display_name,
    version: input.version,
    inputIds: input.inputPorts.reduce((prev: any, curr: any) => {
      if (curr.component.kind === 'resource') {
        return [...prev, curr.component.name];
      }

      if (curr.component.kind === 'component') {
        return [
          ...prev,
          curr.component.system.length
            ? `${curr.component.system.at(0).data.id}_${curr.component.id}`
            : `${curr.component.id}_${curr.component.id}`,
        ];
      }

      return undefined;
    }, []),
  };
}

/**
 * Parse data sources where a given data product fetches data from.
 * @param data - result of graphql query: GET_DATA_PRODUCT_INSTANCE_DEPENDENCIES_BY_ID_AND_ENV_ID
 * @returns list of nodes
 */
function parseSources(data: SystemDependencyEntity): Map<string, any> {
  const sourcesMap = new Map<string, any>();
  data.inputPorts.forEach((port: InputPortDependencyEntity) => {
    let sourceValue;
    switch (port.component.kind) {
      case 'resource':
        sourceValue = {
          id: port.component.name,
          name: port.component.name,
          displayName: port.component.display_name,
          type: port.component.kind,
        };
        break;
      case 'component':
        sourceValue = {
          id: new DependencyNodeId(
            port.component.system.at(0)?.data.id.toString() ??
              port.component.id.toString(),
            port.component.id.toString(),
          ),
          name: port.component.system.at(0)?.data.name ?? port.component.name,
          displayName:
            port.component.system.at(0)?.data.display_name ??
            port.component.display_name,
          type: port.component.kind,
          version: port.component.system.at(0)?.data.version,
          domain:
            port.component.system.at(0)?.data.domain.at(0)?.data.name ??
            port.component.domain.at(0)?.data.name ??
            'Deleted',
        };
        break;
      default:
        break;
    }
    if (sourceValue)
      sourcesMap.set(sourceValue?.id?.toString() || 'resource', sourceValue);
  });
  return sourcesMap;
}

/**
 * Parses consumers of the given data product.
 * To do this it starts by looking up inside the OutputPorts of the given Data Product
 * and from each of those look up inside their InputPorts (i.e. the consumers of that OutputPort)
 * @param data - result of graphql query: GET_DATA_PRODUCT_INSTANCE_DEPENDENCIES_BY_ID_AND_ENV_ID
 * @returns list of nodes
 */
function parseConsumers(
  data: SystemDependencyEntity,
): Map<string, DependencyNode> {
  const consumersMap = new Map<string, DependencyNode>();
  data.outputPorts.forEach((op: OutputPortDependencyEntity) => {
    op.data.system.forEach(system => {
      const consumerValue = {
        id: new DependencyNodeId(
          system.data.id!.toString(),
          op.data.id.toString(),
        ),
        name: system.data.name,
        displayName: system.data.display_name,
        type: op.data.kind,
        version: system.data.version,
        domain: system.data.domain.at(0)?.data.name ?? 'Unassigned',
        inputIds: [data.id.toString()],
      };

      consumersMap.set(
        system.data.id.toString() ?? op.data.id.toString(),
        consumerValue as any,
      );
    });
  });
  return consumersMap;
}

export function parseDependenciesNodes(
  data: SystemDependenciesEntities | undefined,
): DependencyNode[] {
  if (!data) {
    throw new Error(
      'Could not show depedencies since there is no data. Please, check your query.',
    );
  }

  if (data.instances.length === 0 || data.instances.length > 1) {
    throw new Error(
      'Could not show dependencies for more than one Data Product at once. Check your query.',
    );
  }

  return data.instances.flatMap(instance => [
    parseDataProduct(instance),
    ...Array.from(parseSources(instance).values()),
    ...Array.from(parseConsumers(instance).values()),
  ]);
}
