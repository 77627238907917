import { Action, TargetStatus } from '@agilelab/plugin-wb-builder-common';
import { DeploymentPreview } from '../types';

export type Preview = {
  action: Action;
  targetStatus: TargetStatus;
  componentId: string;
};

/**
 * Converts an array of DeploymentPreview objects to an array of Preview objects
 * @param  {DeploymentPreview[]} previews The input array
 * @param  {Boolean} extractSubcomponents If true, subcomponents from each DeploymentPreview will be extracted and added to the result array as well (in a flattened way)
 * @return {Preview[]} The result array
 */
export const createPreviews = (
  previews: DeploymentPreview[],
  extractSubcomponents = true,
): Preview[] => {
  const newPreviews: Preview[] = [];

  previews.forEach(p => {
    newPreviews.push({
      action: p.action!,
      targetStatus: p.newState!,
      componentId: p.id,
    });
    if (extractSubcomponents && p.components)
      p.components.forEach(c => {
        newPreviews.push({
          action: p.action!,
          targetStatus: p.newState!,
          componentId: c.id,
        });
      });
  });

  return newPreviews;
};
