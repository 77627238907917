import { CatalogInfoFile } from '@agilelab/plugin-wb-builder-common';
import {
  PanelNames,
  WbDiffEditor,
  WbLogsViewer,
} from '@agilelab/plugin-wb-platform';
import { Content, StructuredMetadataTable } from '@backstage/core-components';
import { JsonObject } from '@backstage/types';
import { Box, Paper } from '@material-ui/core';
import React from 'react';
import { AdditionalStep, getReviewData } from './MultistepJsonForm';
import yaml from 'yaml';
import { ClassNameMap } from '@material-ui/styles';

export const reviewStep = (
  title: string,
  nextButtonText: string,
  formState: Record<string, any>,
  steps: Array<{
    title: string;
    description?: string;
    schema: JsonObject;
  }>,
  handleFormReset: () => void,
  handleCreate: () => Promise<void>,
): AdditionalStep => ({
  title,
  component: (
    <Content>
      <Paper
        style={{ backgroundColor: 'white', wordBreak: 'break-word' }}
        square
        elevation={0}
      >
        <StructuredMetadataTable
          dense
          metadata={getReviewData(formState, steps)}
        />
        <Box mb={4} />
      </Paper>
    </Content>
  ),
  disableStepButtons: false,
  backButtonText: 'Back',
  nextButtonText: nextButtonText,
  analyticsEvent: 'create',
  resetButtonText: 'Reset',
  resetAction: handleFormReset,
  nextAction: handleCreate,
});

export const processingStep = (
  logAsString: string,
  isProcessingComplete: boolean,
  nextAction: () => Promise<void>,
  className: string,
  nextButtonText: string = 'Next step',
): AdditionalStep => ({
  title: 'Processing',
  component: (
    <Box className={className}>
      <WbLogsViewer text={logAsString} />
    </Box>
  ),
  disableStepButtons: !isProcessingComplete,
  customDisableStepButtons: { disableBack: false },
  backButtonText: 'Back',
  nextButtonText: nextButtonText,
  nextAction,
});

export const comparisonStep = (options: {
  sourceCatalogInfo: CatalogInfoFile | undefined;
  mergedCatalogInfo: Record<string, any> | undefined;
  isLoading: boolean;
  classes: ClassNameMap<'button' | 'saveButton'>;
  additionalActions: JSX.Element[];
}): AdditionalStep => ({
  title: 'Preview and Save',
  component: (
    <Box>
      <PanelNames
        leftPanelTitle="Source Catalog Info"
        rightPanelTitle="Modified Catalog Info"
      />
      <WbDiffEditor
        height="calc(100vh - 305px)"
        width="100%"
        theme="diffEditorWizard"
        language="yaml"
        original={options.sourceCatalogInfo?.content}
        modified={yaml.stringify(options.mergedCatalogInfo)}
        options={{
          selectOnLineNumbers: true,
          contextmenu: false,
          readOnly: true,
          diffCodeLens: false,
          originalEditable: false,
          glyphMargin: true,
          linkedEditing: false,
          minimap: {
            enabled: false,
          },
        }}
      />
    </Box>
  ),
  additionalActions: options.additionalActions,
  resetButtonText: 'Start over',
  disableStepButtons: false,
});
