/*
 * This component is copied from Backstage 1.8.2.
 * CHANGES:
 * - Added custom UI
 * - Add useEffect to allow filter clear
 */

import { Entity, RELATION_OWNED_BY } from '@backstage/catalog-model';
import {
  useEntityList,
  EntityOwnerFilter,
  getEntityRelations,
  humanizeEntityRef,
} from '@backstage/plugin-catalog-react';
import React, { useEffect, useMemo, useState } from 'react';
import { EnumFilter } from '../WbTableFilters';

export const EntityOwnerPicker: React.FC = () => {
  const {
    updateFilters,
    backendEntities,
    filters,
    queryParameters: { owners: ownersParameter },
  } = useEntityList();

  const queryParamOwners = useMemo(
    () => [ownersParameter].flat().filter(Boolean) as string[],
    [ownersParameter],
  );

  const [selectedOwners, setSelectedOwners] = useState(
    queryParamOwners.length ? queryParamOwners : filters.owners?.values ?? [],
  );

  // Set selected owners on query parameter updates; this happens at initial page load and from
  // external updates to the page location.
  useEffect(() => {
    if (queryParamOwners.length) {
      setSelectedOwners(queryParamOwners);
    }
  }, [queryParamOwners]);

  const availableOwners = useMemo(
    () =>
      [
        ...new Set(
          backendEntities
            .flatMap((e: Entity) =>
              getEntityRelations(e, RELATION_OWNED_BY).map(o =>
                humanizeEntityRef(o, { defaultKind: 'group' }),
              ),
            )
            .filter(Boolean) as string[],
        ),
      ].sort(),
    [backendEntities],
  );

  useEffect(() => {
    if (filters.owners === undefined) {
      setSelectedOwners([]);
    }
  }, [filters.owners]);

  useEffect(() => {
    updateFilters({
      owners:
        selectedOwners.length && availableOwners.length
          ? new EntityOwnerFilter(selectedOwners)
          : undefined,
    });
  }, [selectedOwners, updateFilters, availableOwners]);

  return (
    <EnumFilter<string>
      field="Owner"
      onChange={value => setSelectedOwners(value || [])}
      options={availableOwners}
      renderOption={o => o}
      renderValue={v => v}
      value={selectedOwners}
      onSearch={v => availableOwners.filter(o => new RegExp(v, 'ig').test(o))}
    />
  );
};
