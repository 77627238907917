import React, { useMemo } from 'react';

import { stringifyEntityRef } from '@backstage/catalog-model';
import { Typography } from '@material-ui/core';
import pluralize from 'pluralize';

import {
  ALL_TAXONOMIES_FILTER,
  useTaxonomySelection,
} from '@agilelab/plugin-wb-practice-shaper';
import {
  WbSelectInline,
  WbSelectInlineDivider,
  WbSelectInlineItem,
} from '@agilelab/plugin-wb-platform';
import { configApiRef, useApi } from '@backstage/core-plugin-api';

type TaxonomySelectorProps = {
  disabled?: boolean;
  onChange?: (e: string) => void;
  localStorageKey?: string;
  queryParamKey?: string;
};

export const TaxonomySelector = ({
  onChange,
  disabled,
  localStorageKey,
  queryParamKey,
}: TaxonomySelectorProps) => {
  const options = useMemo(
    () => ({ localStorageKey, queryParamKey }),
    [localStorageKey, queryParamKey],
  );
  const configApi = useApi(configApiRef);

  const { selectableTaxonomies, selectedTaxonomyRef, setSelectedTaxonomyRef } =
    useTaxonomySelection(options);

  const isSelectedTaxonomyValid = selectableTaxonomies.some(
    t => stringifyEntityRef(t) === selectedTaxonomyRef,
  );
  const showAllTaxonomyOption = selectableTaxonomies.length !== 1;

  // the selector should be disable if there is only one available taxonomy,
  // but only if the currently selected one is already a valid one, so that if it isn't the user can choose the correct one
  const shouldDisable =
    selectableTaxonomies.length === 1 && isSelectedTaxonomyValid;

  const label = configApi.getString('practiceShaper.taxonomy.label');
  const pluralizedLabel =
    configApi.getOptionalString('practiceShaper.taxonomy.pluralizedLabel') ??
    pluralize(label);

  return (
    <WbSelectInline
      title={`${label}:`}
      menuTitle={pluralizedLabel}
      value={selectedTaxonomyRef || ALL_TAXONOMIES_FILTER}
      disabled={disabled || shouldDisable}
      onChange={e => {
        setSelectedTaxonomyRef(e);
        if (onChange) onChange(e);
      }}
    >
      {showAllTaxonomyOption && (
        <WbSelectInlineItem
          {...{ key: ALL_TAXONOMIES_FILTER, value: ALL_TAXONOMIES_FILTER }}
        >
          <Typography variant="body2">All {pluralizedLabel}</Typography>
        </WbSelectInlineItem>
      )}
      {showAllTaxonomyOption && (
        <WbSelectInlineDivider style={{ marginBlock: '8px' }} />
      )}
      {...selectableTaxonomies.map(t => (
        <WbSelectInlineItem
          {...{ key: t.metadata.name, value: stringifyEntityRef(t) }}
        >
          <Typography variant="body2">
            {(t.metadata.displayName as string) || t.metadata.name}
          </Typography>
        </WbSelectInlineItem>
      ))}
    </WbSelectInline>
  );
};
