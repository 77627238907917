import React from 'react';
import { CustomLogoProps } from './types';
import { Box } from '@material-ui/core';

import poweredByHorizontal from './powered-by-witboost_horiz.svg';

interface Props {
  customLogo: CustomLogoProps;
}

const ColumnLogo: React.FC<Props> = ({ customLogo }) => {
  return (
    <Box
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
        width: '100%',
        gap: '4px',
        padding: '6px 16px',
      }}
    >
      <img
        style={{ maxHeight: 'calc(100% - 16px - 4px)', maxWidth: '100%' }}
        src={customLogo.url}
        alt="custom logo"
      />
      <img
        style={{ height: '16px' }}
        src={poweredByHorizontal}
        alt="powered by witboost"
      />
    </Box>
  );
};

export const CustomLogo: React.FC<Props> = ({ customLogo }) => {
  return <ColumnLogo customLogo={customLogo} />;
};
