import { useTheme, Box, Link } from '@material-ui/core';
import React from 'react';
import { WbTagsModal } from './WbTagsModal';
import { Tag, TagKind, WbTag } from './WbTag';

interface WbTagsTableCellProps {
  tags: Array<Tag>;
  kind?: TagKind;
}

export const WbTagsTableCell: React.FC<WbTagsTableCellProps> = ({
  tags,
  kind = TagKind.generic,
}) => {
  const theme = useTheme();
  const [open, setOpen] = React.useState<boolean>(false);

  const handleClick:
    | (React.MouseEventHandler<HTMLAnchorElement> &
        React.MouseEventHandler<HTMLSpanElement>)
    | undefined = e => {
    e.preventDefault();
    e.stopPropagation();
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  if (tags.length > 3) {
    return (
      <>
        <Box
          style={{
            display: 'flex',
            gap: theme.spacing(1),
            flexWrap: 'wrap',
            maxWidth: '100%',
          }}
        >
          {tags.slice(0, 3).map((tag, i) => (
            <WbTag
              key={i}
              tag={tag}
              kind={kind}
              tooltip
              style={{ maxWidth: '100%' }}
            />
          ))}

          <Link
            style={{
              height: '24px',
              fontSize: '13px',
              display: 'flex',
              alignItems: 'center',
            }}
            onClick={handleClick}
          >
            {`...other ${tags.length - 3}`}
          </Link>
        </Box>
        <WbTagsModal
          maxWidth="md"
          open={open}
          onClose={handleClose}
          tags={tags}
          kind={kind}
        />
      </>
    );
  }
  return (
    <Box style={{ display: 'flex', flexWrap: 'wrap', gap: theme.spacing(1) }}>
      {tags.map((tag, i) => (
        <WbTag
          key={i}
          tag={tag}
          style={{ maxWidth: '100%' }}
          kind={kind}
          tooltip
        />
      ))}
    </Box>
  );
};
