import React from 'react';
import { Chip, ChipProps } from '@material-ui/core';
import { useStyles } from './styles';
import ClearIcon from '@material-ui/icons/Clear';

export const WbChip: React.FC<ChipProps> = props => {
  const classes = useStyles();
  return (
    <Chip
      {...props}
      size="small"
      className={classes.multiSelectChip}
      classes={{
        label: classes.chipLabel,
      }}
      deleteIcon={<ClearIcon onMouseDown={event => event.stopPropagation()} />}
    />
  );
};
