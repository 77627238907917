export const identifierForbiddenCharsRegex = new RegExp(/[ ,.;:]+/g);
export const validEntityNameChars = new RegExp(
  /^([A-Za-z0-9][-A-Za-z0-9_.]*)?[A-Za-z0-9]$/,
);

export function generateIdentifier(
  name: string,
  domain: string,
): string | null {
  if (!name || !domain) {
    return null;
  }
  const nameWithoutSpecialCharacters = sanitizeEntityName(name);
  const domainWithoutPrefix = domain.split(':')[1]?.toLowerCase();
  return `${domainWithoutPrefix}.${nameWithoutSpecialCharacters}.0`;
}

export function generateComponentIdentifier(
  name: string,
  dataproduct: string,
): string | null {
  if (!name || !dataproduct) {
    return null;
  }

  const nameWithoutSpecialCharacters = sanitizeEntityName(name);

  const dataproductWithoutPrefix = dataproduct.split(':')[1]?.toLowerCase();
  return `${dataproductWithoutPrefix}.${nameWithoutSpecialCharacters}`;
}

function sanitizeEntityName(name: string): string {
  return name
    .normalize('NFD')
    .trim()
    .replace(/\p{Diacritic}/gu, '')
    .replace(identifierForbiddenCharsRegex, '-')
    .toLowerCase();
}
