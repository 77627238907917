import { IconButton, Menu, MenuItem, Typography } from '@material-ui/core';
import React, { useMemo, useState } from 'react';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { DeployUndeployDialogComponent } from './DeployUndeployDialog';
import {
  DeploymentUnitStatus,
  ReleaseAndDeployments,
} from '@agilelab/plugin-wb-builder-common';
import { useRouteRef } from '@backstage/core-plugin-api';
import { useNavigate } from 'react-router';
import { releaseDetailRouteRef } from '../../../../routes';
import { useReleasePageContext } from '../../context/useReleasePageContext';
import { Environment } from '@agilelab/plugin-wb-platform-common';
import { createSearchParams } from 'react-router-dom';
import { useSelectorsContext } from '@agilelab/plugin-wb-platform';
import { Action } from './types';

interface RowMenuProps {
  release: ReleaseAndDeployments;
}

export const RowMenu: React.FC<RowMenuProps> = ({ release }) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const releaseDetailRoute = useRouteRef(releaseDetailRouteRef);
  const navigate = useNavigate();
  const { environmentList } = useSelectorsContext();
  const { deployAll, undeployAll, permissions } = useReleasePageContext();
  const [action, setAction] = useState<Action | null>(null);
  const [openDialog, setOpenDialog] = useState<{
    state: boolean;
    action?: Action;
    version?: string;
  }>({ state: false });

  const allowedEnvsList = useMemo(
    () =>
      environmentList.filter(env => {
        const pEnvName = `builder.dp.deploy.${env.name}`;

        return permissions?.data?.find(
          p => pEnvName === p.permission && p.result === 'ALLOW',
        );
      }),
    [environmentList, permissions?.data],
  );

  const undeployEnvsList = (() => {
    const deployed = new Set(
      release.deployments
        .filter(d => d.status === DeploymentUnitStatus.DEPLOYED)
        .map(e => e.environment),
    );
    return allowedEnvsList.filter(env => {
      // environments that aren't currently deployed shouldn't appear in the selection
      return deployed.has(env.name);
    });
  })();

  const openMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const closeMenu = () => {
    setAnchorEl(null);
  };

  const handleClick = (
    event: React.BaseSyntheticEvent,
    activeAction: Action,
  ) => {
    event.preventDefault();
    event.stopPropagation();
    setAction(activeAction);
    setOpenDialog({
      state: true,
      action: activeAction,
      version: release.metadata.version,
    });
    closeMenu();
  };

  const handleClose = async (
    env?: Environment,
    provisionAndNavigate: boolean = false,
  ) => {
    setOpenDialog({ state: false });
    if (!provisionAndNavigate) return;
    if (env) {
      if (action === Action.Deploy) await deployAll(release, env);
      if (action === Action.Undeploy) await undeployAll(release, env);
    }

    const namespace = release.metadata.namespace ?? 'default';
    const name = release.metadata.name;
    const kind = 'Release';
    const params = env
      ? {
          env: env.name,
        }
      : undefined;

    navigate({
      pathname: releaseDetailRoute({ kind, namespace, name }),
      search: createSearchParams(params).toString(),
    });
  };

  return (
    <>
      {permissions?.anyAllowed && (
        <IconButton onClick={openMenu} size="small" color="secondary">
          <MoreVertIcon />
        </IconButton>
      )}
      <Menu
        open={open}
        anchorEl={anchorEl}
        onClose={(e: React.MouseEvent<HTMLButtonElement>) => {
          e.preventDefault();
          e.stopPropagation();
          closeMenu();
        }}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <MenuItem onClick={e => handleClick(e, Action.Deploy)}>
          <Typography>Deploy</Typography>
        </MenuItem>
        <MenuItem
          disabled={!undeployEnvsList.length}
          onClick={e => handleClick(e, Action.Undeploy)}
        >
          <Typography>Undeploy</Typography>
        </MenuItem>
      </Menu>
      {openDialog.state && (
        <DeployUndeployDialogComponent
          envList={
            openDialog.action === Action.Deploy
              ? allowedEnvsList
              : undeployEnvsList
          }
          open={openDialog}
          onClose={handleClose}
        />
      )}
    </>
  );
};
