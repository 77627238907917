import { get } from 'lodash';

export const getObjectPropFromString = (
  obj: Record<string | number, any>,
  key: string,
  defaultValue?: (obj: Record<string | number, any>) => string,
  formatter?: (value: any) => string,
): string => {
  const property = get(obj, key);
  if (property === undefined) {
    return defaultValue ? defaultValue(obj) : property;
  }
  return formatter ? formatter(property) : property.toString();
};
