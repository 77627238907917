import { gql } from '@apollo/client/core';

export const GET_DATA_PRODUCT_INSTANCE_DEPENDENCIES_BY_ID_AND_ENV_ID = gql`
  query GetDataProductInstanceDependenciesByIdAndEnvId(
    $id: bigint!
    $envId: bigint!
  ) {
    instances: marketplace_instances(
      where: { id: { _eq: $id }, environment_id: { _eq: $envId } }
    ) {
      # data product section
      id
      name
      display_name
      domain: relations(where: { name: { _eq: "partOfDomain" } }) {
        data: instanceByTargetInstanceId {
          name
        }
      }
      owner
      owner_display_name
      #input port
      inputPorts: relations(
        where: {
          _and: [
            { name: { _eq: "readsFrom" } }
            {
              _or: [
                { instanceByTargetInstanceId: { kind: { _eq: "resource" } } }
                {
                  instanceByTargetInstanceId: {
                    kind: { _eq: "component" }
                    relations: {
                      name: { _eq: "partOfSystem" }
                      instanceByTargetInstanceId: { kind: { _eq: "system" } }
                    }
                  }
                }
              ]
            }
          ]
        }
      ) {
        component: instanceByTargetInstanceId {
          id
          name
          display_name
          kind
          domain: relations(where: { name: { _eq: "partOfDomain" } }) {
            data: instanceByTargetInstanceId {
              name
            }
          }
          system: relations(where: { name: { _eq: "partOfSystem" } }) {
            data: instanceByTargetInstanceId {
              id
              version
              name
              display_name
              domain: relations(where: { name: { _eq: "partOfDomain" } }) {
                data: instanceByTargetInstanceId {
                  name
                }
              }
            }
          }
        }
      }
      outputPorts: relationsByTargetInstanceId(
        where: {
          _and: [
            { name: { _eq: "partOfSystem" } }
            {
              instance: {
                relationsByTargetInstanceId: { name: { _eq: "readsFrom" } }
              }
            }
          ]
        }
      ) {
        # partendo dall'output port
        data: instance {
          id
          kind
          type
          # 'readsFrom' relations where this port is target
          system: relationsByTargetInstanceId(
            where: {
              name: { _eq: "readsFrom" }
              instance: { kind: { _eq: "system" } }
            }
          ) {
            # 'readsFrom' source (i.e., system)
            data: instance {
              id
              version
              environment {
                name
              }
              name
              display_name
              domain: relations(where: { name: { _eq: "partOfDomain" } }) {
                data: instanceByTargetInstanceId {
                  name
                }
              }
            }
          }
        }
      }
    }
  }
`;
