import {
  ListItemIcon,
  MenuItem,
  MenuItemProps,
  Popper,
  PopperProps,
  useTheme,
} from '@material-ui/core';
import ArrowForwardIos from '@material-ui/icons/ArrowForwardIos';
import React from 'react';
import { useRef, useState } from 'react';

type SubMenuItemProps = MenuItemProps & {
  button?: true;
  label: string;
} & Pick<PopperProps, 'placement'>;

export const SubMenu = (props: SubMenuItemProps) => {
  const [open, setOpen] = useState(false);
  const ref = useRef<HTMLLIElement | null>(null);
  const [isHovered, setIsHovered] = useState(false);
  const theme = useTheme();

  return (
    <MenuItem
      {...props}
      ref={ref}
      onMouseEnter={() => {
        setOpen(true);
        setIsHovered(true);
      }}
      onMouseLeave={() => {
        setOpen(false);
        setIsHovered(false);
      }}
      style={{
        background: isHovered ? theme.palette.bkg.primary : theme.palette.white,
        color: isHovered
          ? theme.palette.primary.main
          : theme.palette.secondary.main,
        fontSize: 14,
        fontWeight: 500,
        display: 'flex',
        justifyContent: 'space-between',
      }}
    >
      <span>{props.label}</span>
      <Popper
        anchorEl={ref.current}
        open={open}
        placement={props.placement ?? 'left-start'}
        modifiers={{
          flip: {
            enabled: true,
          },
          preventOverflow: {
            enabled: true,
            boundariesElement: 'viewport',
          },
        }}
      >
        {props.children}
      </Popper>
      <ListItemIcon>
        <ArrowForwardIos
          style={{
            marginLeft: 'auto',
            color: isHovered
              ? theme.palette.primary.main
              : theme.palette.secondary.main,
          }}
          fontSize="inherit"
        />
      </ListItemIcon>
    </MenuItem>
  );
};
