import { TablePagination } from '@material-ui/core';
import React from 'react';

export const WbPagination: React.FC<{
  rowsPerPageOptions?: number[];
  count: number;
  offset: number;
  limit: number;
  onPageChange: (page: number) => void;
  onRowsPerPageChange: (rowsPerPage: number) => void;
  style?: React.CSSProperties;
}> = ({
  rowsPerPageOptions,
  count,
  offset,
  limit,
  onPageChange,
  onRowsPerPageChange,
  style,
}) => {
  const handleChangePage = (
    _event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ) => {
    onPageChange(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    onRowsPerPageChange(parseInt(event.target.value, 10));
  };

  return (
    <TablePagination
      rowsPerPageOptions={rowsPerPageOptions || [25, 50, 75, 100, 200]}
      component="div"
      count={count}
      rowsPerPage={limit}
      page={offset / limit}
      onPageChange={handleChangePage}
      onRowsPerPageChange={handleChangeRowsPerPage}
      style={{ alignSelf: 'flex-end', ...style }}
    />
  );
};
