import { Link, Tooltip } from '@material-ui/core';
import React from 'react';
import { NavigateFunction } from 'react-router-dom';

interface WbLinkProps {
  /**
   * the route you want to navigate to.
   * if it starts with `/`, it places the route after the host,
   * otherwise it appends the route after the current link.
   * @example current route: `localhost:3000/other_route`
   *          route = `/marketplace/test` -> will navigate to `localhost:3000/marketplace/test`
   *          route = `marketplace/test` -> will navigate to `localhost:3000/other_route/marketplace/test`
   */
  route?: string;
  /**
   * optionally provide an external link, it will have priority.
   * @example https://testsite.com
   */
  externalLink?: string;
  /**
   * the text to display instead of the raw link.
   * If not provided, show raw link instead.
   */
  text?: string;
  /**
   * pass `useNavigate()` from the parent object.
   * @example <WbLink navigate={useNavigate()} />
   */
  navigate: NavigateFunction;
  /**
   * defaults to `always`
   */
  underlineLink?: 'none' | 'hover' | 'always';
  className?: string;
  showTooltip?: boolean;
}

export const WbLink: React.FC<WbLinkProps> = ({
  route,
  externalLink,
  text,
  navigate,
  underlineLink,
  className,
  showTooltip,
}) => {
  const handleClick = () => {
    if (externalLink) {
      open(externalLink, '_blank', '');
      return;
    }
    if (route) navigate(route);
  };

  const toDisplay = text ?? externalLink ?? route ?? '';

  return (
    <Tooltip title={showTooltip ? toDisplay : ''}>
      <Link
        className={className}
        style={{ cursor: 'pointer' }}
        underline={underlineLink ?? 'always'}
        onClick={handleClick}
      >
        {toDisplay}
      </Link>
    </Tooltip>
  );
};
