import { z } from 'zod';
import { GovernanceEntityType, Outcome } from '../../enums';
import { EvaluationResultResult } from '../evaluation-result';
import { Metric } from '../metric';
import { Policy } from '../policy';

const allowedOutcomes = [
  Outcome.Ok,
  Outcome.Error,
  Outcome.Info,
  Outcome.NotBlockinError,
  Outcome.Warning,
  Outcome.NotExecuted,
  Outcome.Pending,
];

export const LatestResourceEvaluationsRequestSchema = z.object({
  resources: z.string().array().describe('Resource names'),
  environment: z.string().min(1),
  governanceEntityType: z.nativeEnum(GovernanceEntityType),
  outcomes: z
    .nativeEnum(Outcome)
    .describe('Evaluation result outcomes to filter by')
    .refine(o => allowedOutcomes.includes(o))
    .array()
    .optional(),
  includeGovernanceEntityDetails: z
    .boolean()
    .describe(
      'Whether to include full governance entity details in the results or not (faster)',
    )
    .default(false),
  startDate: z
    .string()
    .describe('Lower bound of the date interval (inclusive)')
    .transform(str => new Date(str))
    .optional(),
  endDate: z
    .string()
    .describe('Upper bound of the date interval (exclusive)')
    .transform(str => new Date(str))
    .optional(),
});

export type LatestResourceEvaluationsRequest = z.infer<
  typeof LatestResourceEvaluationsRequestSchema
>;

export type ResourceEvaluation = {
  resource: string;
  governanceEntityId: string;
  outcome: Outcome;
  evaluationResultId: number;
  evaluationResult: EvaluationResultResult;
  evaluationTime: string;
  governanceEntity?: Policy | Metric;
};
