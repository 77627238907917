import { RefObject, useCallback, useState } from 'react';
import useResizeObserver from './useResizeObserver';

export function useElementSize<T extends HTMLElement>(ref: RefObject<T>) {
  const [size, setSize] = useState({
    clientWidth: 0,
    scrollWidth: 0,
    clientHeight: 0,
    scrollHeight: 0,
  });

  const cb = useCallback((target: T) => {
    const { clientWidth, scrollWidth, clientHeight, scrollHeight } = target;

    setSize({
      clientWidth,
      scrollWidth,
      clientHeight,
      scrollHeight,
    });
  }, []);

  useResizeObserver(cb, ref);

  return size;
}
