/*
 * Copyright 2022 The Backstage Authors
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
import { Box } from '@material-ui/core';
import React, { useEffect, useRef } from 'react';
import { DismissableBanner } from '@backstage/core-components';

type TaskErrorsProps = {
  error?: Error;
};

export const TaskErrors = ({ error }: TaskErrorsProps) => {
  const id = useRef('');

  useEffect(() => {
    id.current = String(Math.random());
  }, [error]);

  if (!error) return null;

  // Needed to handle nested Backstage errors coming for instance from the Backstage register action
  let nestedBackstageErrorMessage = null;
  try {
    nestedBackstageErrorMessage = JSON.parse(
      JSON.parse(error.message).error.message,
    ).error.message;
    // eslint-disable-next-line no-empty
  } catch {}

  return (
    <Box>
      <DismissableBanner
        id={id.current}
        variant="error"
        message={nestedBackstageErrorMessage || error.message}
      />
    </Box>
  );
};
