/*
 * Copyright 2020 The Backstage Authors
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
import { CatalogClientExtension } from '@agilelab/plugin-wb-catalog-extension-common';
import { CodeSnippet, WbTable } from '@agilelab/plugin-wb-platform';
import { DEFAULT_NAMESPACE, parseEntityRef } from '@backstage/catalog-model';
import {
  TableColumn,
  TableProps,
  WarningPanel,
} from '@backstage/core-components';
import {
  identityApiRef,
  useApi,
  useRouteRef,
} from '@backstage/core-plugin-api';
import { entityRouteRef, useEntityList } from '@backstage/plugin-catalog-react';
import React, { ReactNode, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router';
import useAsync from 'react-use/lib/useAsync';
import { extensionApiRef } from '../../CatalogExtensionApiRef';
import { CatalogTableFilters } from './CatalogTableFilters/CatalogTableFilters';
import { columnFactories } from './columns';
import { useColumns } from './hooks/useColumns';
import { useRows } from './hooks/useRows';
import { CatalogTableRow, CatalogTableSort } from './types';

/**
 * Props for {@link CatalogTable}.
 *
 * @public
 */
export interface CatalogTableProps {
  columns?: TableColumn<CatalogTableRow>[];
  actions?: TableProps<CatalogTableRow>['actions'];
  tableOptions?: TableProps<CatalogTableRow>['options'];
  emptyContent?: ReactNode;
  subtitle?: string;
  isSoftwareCatalog?: boolean;
  filtersNode?: JSX.Element;
  columnKind?: 'system';
  preUrl?: string;
  availableResourceTypes?: Map<string, { displayName: string }>;
}

/** @public */
export const CatalogTable = (props: CatalogTableProps) => {
  const {
    columns,
    actions,
    isSoftwareCatalog = false,
    filtersNode,
    columnKind,
    preUrl = '',
    availableResourceTypes,
  } = props;
  const { loading, error, entities, filters } = useEntityList();
  const catalogApi = useApi(extensionApiRef) as CatalogClientExtension;
  const identityApi = useApi(identityApiRef);
  const entityRoute = useRouteRef(entityRouteRef);
  const navigate = useNavigate();

  const { value: catalogResponse } = useAsync(() => {
    return catalogApi.getEntities({
      filter: { kind: ['Domain'] },
    });
  }, [catalogApi]);

  const { value: dpResponse } = useAsync(() => {
    return catalogApi.getEntities({
      filter: { kind: ['system'] },
    });
  }, [catalogApi]);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [sortBy, setSortBy] = useState<CatalogTableSort>({
    field: 'entity.spec.mesh.name',
    direction: 'asc',
  });

  const { value: errors } = useAsync(async () => {
    const entityIds = entities.flatMap(e =>
      e.metadata.uid ? [e.metadata.uid] : [],
    );
    const { token } = await identityApi.getCredentials();
    const result = await catalogApi.getEntityErrors(entityIds, { token });
    return result ?? [];
  }, [catalogApi, entities]);

  const defaultColumns: TableColumn<CatalogTableRow>[] = useMemo(() => {
    return [...createEntitySpecificColumns()];
    function createEntitySpecificColumns(): TableColumn<CatalogTableRow>[] {
      switch (columnKind ?? filters.kind?.value.toLowerCase()) {
        case 'user':
          return [
            columnFactories.createUserNameColumn(),
            columnFactories.createUserEmailColumn(),
          ];
        case 'domain':
          return [
            columnFactories.createNameColumn({
              defaultKind: filters.kind?.value,
            }),
            columnFactories.createDomainTypeColumn({
              title: 'Domain Type',
              fieldPath: 'spec.instanceOf',
            }),
            columnFactories.createMetadataDescriptionColumn(),
            columnFactories.createOwnerColumn(),
            columnFactories.createTagsColumn(),
          ];
        case 'system':
          return [
            columnFactories.createQualifiedNameColumn(),
            columnFactories.createVersionColumn(),
            columnFactories.createProjectTypeColumn(availableResourceTypes),
            columnFactories.createMetadataDescriptionColumn(),
            columnFactories.createDomainDataProductColumn(
              catalogResponse?.items || [],
            ),
            columnFactories.createOwnerColumn(),
          ];
        case 'group':
          return [
            columnFactories.createNameColumn({
              defaultKind: filters.kind?.value,
            }),
            columnFactories.createMetadataDescriptionColumn(),
            columnFactories.createSpecTypeColumn(),
          ];
        case 'location':
          return [
            columnFactories.createNameColumn({
              defaultKind: filters.kind?.value,
            }),
            columnFactories.createMetadataDescriptionColumn(),
            columnFactories.createSpecTypeColumn(),
          ];
        case 'template':
          return [
            columnFactories.createNameColumn({
              defaultKind: filters.kind?.value,
            }),
            columnFactories.createMetadataDescriptionColumn(),
            columnFactories.createSpecTypeColumn(),
            columnFactories.createDomainDataProductColumn(
              catalogResponse?.items || [],
            ),
            columnFactories.createTagsColumn(),
          ];
        case 'component':
          return [
            columnFactories.createQualifiedNameColumn(),
            columnFactories.createMetadataDescriptionColumn(),
            columnFactories.createSpecTypeColumn(),
            columnFactories.createSystemColumn(),
            columnFactories.createVersionColumn(),
            columnFactories.createTagsColumn(),
          ];
        case 'blueprint':
          return [
            columnFactories.createNameColumn({
              defaultKind: filters.kind?.value,
            }),
            columnFactories.createMetadataDescriptionColumn(),
            columnFactories.createDomainDataProductColumn(
              catalogResponse?.items || [],
            ),
            columnFactories.createTagsColumn(),
          ];
        case 'reverseprovisioningtemplate':
          return [
            columnFactories.createNameColumn({
              defaultKind: filters.kind?.value,
            }),
            columnFactories.createOwnerColumn(),
            columnFactories.createMetadataDescriptionColumn(),
            columnFactories.createTagsColumn(),
          ];
        case 'release':
          return [
            columnFactories.createNameColumn({
              defaultKind: filters.kind?.value,
            }),
            columnFactories.createReleaseSystemColumn(),
            columnFactories.createReleaseVersionColumn(),
            // creation date
            columnFactories.createCreationDateColumn(),
          ];
        case 'resource':
          return [
            columnFactories.createNameColumn({
              defaultKind: filters.kind?.value,
            }),
            columnFactories.createMetadataDescriptionColumn(),
            columnFactories.createSpecTypeColumn(),
            columnFactories.createOwnerColumn(),
          ];
        case 'taxonomy':
          return [
            columnFactories.createNamePracticeShaperColumn({
              defaultKind: filters.kind?.value,
            }),
            columnFactories.createMetadataDescriptionColumn(),
            columnFactories.createEnabledColumn(),
          ];
        case 'domaintype':
          return [
            columnFactories.createNamePracticeShaperColumn({
              defaultKind: filters.kind?.value,
            }),
            columnFactories.createMetadataDescriptionColumn(),
          ];
        case 'systemtype':
          return [
            columnFactories.createNamePracticeShaperColumn({
              defaultKind: filters.kind?.value,
            }),
            columnFactories.createMetadataDescriptionColumn(),
            columnFactories.createResourceIDColumn(),
            columnFactories.createTaxonomyColumn(),
            columnFactories.createDomainTypeColumn(),
          ];
        case 'componenttype':
          return [
            columnFactories.createNamePracticeShaperColumn({
              defaultKind: filters.kind?.value,
            }),
            columnFactories.createMetadataDescriptionColumn(),
            columnFactories.createResourceIDColumn(),
            columnFactories.createSystemTypeColumn(),
            columnFactories.createTaxonomyColumn(),
            columnFactories.createDomainTypeColumn(),
          ];

        default:
          return [
            columnFactories.createNameColumn({
              defaultKind: filters.kind?.value,
            }),
            columnFactories.createSystemColumn(),
            columnFactories.createOwnerColumn(),
            columnFactories.createSpecTypeColumn(),
            columnFactories.createMetadataDescriptionColumn(),
          ];
      }
    }
  }, [
    columnKind,
    filters.kind?.value,
    catalogResponse?.items,
    availableResourceTypes,
  ]);
  const filteredRows = useRows({
    errors,
    dpResponse,
    sortBy,
    entities,
  });

  const cols = useColumns({
    columns: columns || defaultColumns,
    actions: actions,
  });

  useEffect(() => {
    setPage(0);
  }, [filters]);

  if (error) {
    return (
      <div>
        <WarningPanel
          severity="error"
          title="Could not fetch catalog entities."
        >
          <CodeSnippet language="text" text={error.toString()} />
        </WarningPanel>
      </div>
    );
  }
  return (
    <>
      <CatalogTableFilters
        isSoftwareCatalog={isSoftwareCatalog}
        filtersNode={filtersNode}
      />

      <WbTable<CatalogTableRow>
        components={{
          tableLoader: { loading },
          tableContent: {
            columns: cols,
            rows:
              filteredRows.slice(
                page * rowsPerPage,
                page * rowsPerPage + rowsPerPage,
              ) || [],
            onRowClick: (e, ev) => {
              const tag = (ev.target as HTMLElement).tagName;
              if (tag !== 'A') {
                const data = {
                  name: e.entity.metadata.name || '',
                  kind: e.entity.kind,
                  namespace: e.entity.metadata.namespace || '',
                };

                data.kind = data.kind.toLocaleLowerCase('en-US');
                data.namespace =
                  data.namespace?.toLocaleLowerCase('en-US') ??
                  DEFAULT_NAMESPACE;

                const ref = parseEntityRef(data);
                navigate(`${preUrl}${entityRoute(ref)}`);
              }
            },
          },
        }}
        onSort={(_, cell, direction) => {
          setSortBy({ field: cell.field, direction: direction ?? 'asc' });
        }}
        pagination={{
          count: filteredRows.length,
          limit: rowsPerPage,
          offset: page * rowsPerPage,
          onPageChange: (newPage: number) => {
            setPage(newPage);
          },
          onRowsPerPageChange: (newRowsPerPage: number) => {
            setRowsPerPage(newRowsPerPage);
          },
        }}
      />
    </>
  );
};

CatalogTable.columns = columnFactories;
