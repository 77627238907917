import { ApiHolder, configApiRef } from '@backstage/core-plugin-api';
import { FieldValidation } from '@rjsf/core';
import {
  CustomUrlOperations,
  parseConfiguration,
  parseDynamicFields,
  validateUiSchema,
} from './CustomUrlUtilities';
import fetch from 'cross-fetch';

/**
 * This method performs a validation on the selected values of the Custom URL Picker. The validation
 * is performed by calling the /validate API of the microservice.
 * @param selectedValues
 * @param validation
 * @param context
 * @param propSchema
 * @param formContext
 * @param fieldName
 * @returns
 */
export const customUrlPickerValidation = async (
  selectedValues: any,
  _validation: FieldValidation,
  context: { apiHolder: ApiHolder },
  propSchema: any,
  formContext: any,
  fieldName?: string,
) => {
  try {
    const configurations = context.apiHolder.get(configApiRef);
    const validatedSchema = validateUiSchema(propSchema);
    const microserviceConfig = parseConfiguration(
      configurations,
      validatedSchema,
      CustomUrlOperations.VALIDATION,
    );

    const allowArbitraryValues =
      (validatedSchema['ui:options']?.allowArbitraryValues as boolean) ?? false;

    const maxNumberOfItems = validatedSchema['ui:options']
      ?.maxNumberToSelect as number | undefined;

    if (!selectedValues || (selectedValues && !selectedValues.length)) {
      return {
        [fieldName!]: {
          __errors: [],
        },
      };
    }

    if (
      maxNumberOfItems &&
      selectedValues &&
      selectedValues.length > maxNumberOfItems
    ) {
      return {
        [fieldName!]: {
          __errors: [
            `It seems that the number of items that you have insert for this field exceed the maximum number enabled: ${maxNumberOfItems}. Please remove some values or the limit.`,
          ],
        },
      };
    }

    if (allowArbitraryValues) {
      return {
        [fieldName!]: {
          __error: [],
        },
      };
    }

    const { limit, ...restParams } =
      validatedSchema['ui:apiSpec'].retrieval.params;

    const xApiKeyHeader = microserviceConfig.apiKey
      ? { 'X-API-Key': microserviceConfig.apiKey }
      : undefined;
    const response = await fetch(
      `${microserviceConfig.baseUrl}${microserviceConfig.validationPath}`,
      {
        method: microserviceConfig.validationMethod,
        headers: {
          'Content-Type': 'application/json',
          ...xApiKeyHeader,
        },
        body: JSON.stringify({
          selectedObjects: selectedValues,
          queryParameters: parseDynamicFields(
            formContext,
            null,
            () => {},
            () => {},
            () => {},
            () => {},
            restParams,
          ),
        }),
      },
    );

    if (response.status === 400) {
      const responseBody = await response.json();
      const errors = responseBody.errors.map((err: any) => err.error);

      return {
        [fieldName!]: {
          __errors: errors,
        },
      };
    }

    if (response.status === 500) {
      const responseBody = await response.json();
      const errorList = responseBody.errors;

      return {
        [fieldName!]: {
          __errors: [
            'It seems that the Microservice you are reaching is down or has thrown some exceptions. Please contact the platform team and whitens the field if you want to go on. Errors:',
            ...errorList,
          ],
        },
      };
    }

    if (response.status === 200) {
      return {
        [fieldName!]: {
          __errors: [],
        },
      };
    }

    return {
      [fieldName!]: {
        __errors: [
          'Error while validating the values selected. Please contact the platform team and whitens the field if you want to go on.',
        ],
      },
    };
  } catch (error) {
    if (error.message !== 'Network request failed') {
      return {
        [fieldName!]: {
          __errors: [error.message],
        },
      };
    }
    return {
      [fieldName!]: {
        __errors: [
          'It seems that the Microservice you are contacting is down, please contact the platform team and whitens the field if you want to go on.',
        ],
      },
    };
  }
};
